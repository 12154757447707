/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import { useDebounce } from "use-debounce";
import { useOutsideClick } from "hooks";
import styled from "styled-components";
import { useQuery } from "react-query";
import { AiFillCaretDown, AiOutlineClose } from "react-icons/ai";
import ClipLoader from "react-spinners/ClipLoader";
import { useMainContext } from "contexts";
import "./style.scss";
import _ from "lodash";

export const AutoComplete = ({
  id,
  value,
  onChange,
  label,
  className,
  backgroundColor = "#eee",
  placeholder = "Search or type here",
  width,
  idField = "id",
  inputId,
  displayValue = "",
  valueProp = "name",
  data,
  func = () => { },
  onSelectId = () => { },
  onSelectValue = () => { },
  onClick = () => { },
  onFocus = () => { },
  onCreateNew = () => { },
  showAddProduct = true
}) => {
  const menuRef = useRef(null);
  const [search, setSearch] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [debouncedValue] = useDebounce(search, 500);
  const [innerValue, setInnerValue] = useState("");
  const { postPageEvents } = useMainContext();

  useOutsideClick(menuRef, clickOutsideMenu);
  function clickOutsideMenu() {
    setShowResults(false);
  }
  const handleChange = (params) => {
    if (params !== value) {
      setSearch("");
      // `title` may not exists in params for every usage
      onChange(params[idField], params.title);
      onSelectId(params[idField]);
      setInnerValue(params[valueProp]);
      onSelectValue(params[valueProp], params);
      postPageEvents("click", params[valueProp]);
    }
    setShowResults(false);
  };

  const { data: queryData, isLoading, isFetching } = useQuery(
    [debouncedValue],
    () => func(debouncedValue),
    {
      keepPreviousData: true,
      enabled: data === undefined && debouncedValue.length > 0,
    }
  );

  const [focused, setFocused] = useState(false);

  const handleBlur = (event) => {
    setFocused(false);
    onFocus(false);
    postPageEvents("blur", id || `${label} Dropdown`);
  };

  const handleClear = () => {
    onChange("");
    setSearch("");
    onSelectId("");
    setInnerValue("");
    onSelectValue("");
  };

  useEffect(() => {
    if (debouncedValue) {
    } else {
      setShowResults(false);
    }
    return () => { };
  }, [debouncedValue]);

  useEffect(() => {
    if (displayValue) {
      setInnerValue(displayValue);
    }
    return () => { };
  }, [displayValue]);

  useEffect(() => {
    if (!value) {
      setInnerValue("");
      setSearch("");
    }
    return () => { };
  }, [value]);

  useEffect(() => {
    // if ((queryData?.results && queryData.results.length > 0) || (data && data.length > 0)) {
    if ((queryData?.results && queryData.results.length > 0)) {
      console.log('userFEffect', queryData, data)
      setShowResults(true);
    }
    return () => { };
  }, [queryData?.results, data]);

  const results = data ? _.isArray(data)
    ? data?.filter(item =>
      item[valueProp].toLowerCase().includes(debouncedValue.toLowerCase())
    ) : [data]
    : queryData?.results;

  return (
    <div className={className} onClick={onClick}>
      {label && <span className="select-label my-auto">{label}</span>}
      <div className="auto-complete" style={{ width }}>
        <div className="menu-button" style={{ width }}>
          {innerValue ? (
            <>
              <InnerValue className="mr-auto" value={innerValue} readOnly />
              <button
                className="menu-button__drop-down"
                onClick={() => handleClear()}
              >
                <AiOutlineClose color="white" alt="clear" />
              </button>
            </>
          ) : (
            <div className="d-flex w-100">
              <StyledInput
                type="text"
                id={inputId || Math.random(100)}
                aria-labelledby={"auto complete"}
                autocomplete="chrome-off"
                onFocus={() => {
                  setFocused(true);
                  onFocus(true);
                  postPageEvents("focus", id || `${label} Dropdown`);
                }}
                onBlur={handleBlur}
                aria-describedby={"auto complete"}
                value={search}
                placeholder={placeholder}
                onChange={(e) => {
                  setSearch(e.target.value);
                  setShowResults(true);
                }}
              />

              {isLoading || isFetching ? (
                <div className="my-auto">
                  <ClipLoader size={16} color="#fff" />
                </div>
              ) : (
                <AiFillCaretDown
                  color="white"
                  className="menu-button__drop-down"
                  alt="drop-down"
                />
              )}
            </div>
          )}

          <Underline focused={focused} />
        </div>
        {showResults && (
          <div className="menu-dropdown" ref={menuRef}>
            <div className="menu-dropdown-content">
              {results && results.length > 0 ? (
                results.map((item, index) => {
                  return (
                    <div
                      className={classNames(
                        "menu-item",
                        item[valueProp] === value ? "active" : ""
                      )}
                      key={`key${index}`}
                      onClick={() => handleChange(item)}
                      style={{ width }}
                    >
                      {item[valueProp]}
                    </div>
                  );
                })
              ) : (
                <div className="my-3 d-flex justify-content-center">
                  No results
                </div>
              )}
            </div>
            {showAddProduct &&
              <div className="create-new" onClick={() => onCreateNew(search)}>
                Add As New {label}
              </div>
            }
          </div>
        )}
      </div>
    </div>
  );
};

const StyledInput = styled.input`
  background: transparent;
  color: white;
  border: none;
  height: 32px;
  line-height: 32px;
  width: calc(100% - 24px);
  font-size: 14px;
  outline: none;
  &:focus,
  &:hover {
    border: none;
    outline: none;
  }
`;

const Underline = styled.div`
  background: ${(props) => props.theme.palette.primary};
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0;
  transform-origin: left;
  transition: transform 500ms ease;
  transform: ${(props) => (props.focused ? "" : "scale3d(0, 1, 1)")};
`;

const InnerValue = styled.input`
  background: transparent;
  border: none;
  outline: none;
  color: white;
  width: 300px;
  &:focus {
    border: none;
    outline: none;
  }
`;
