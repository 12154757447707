import { postPageEvents } from "api";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { FaCheckCircle, FaClone, FaInfoCircle, FaMinusCircle, FaTrash } from "react-icons/fa";
import styled from "styled-components";

export const ProductList = ({
  productsData = [],
  sizeData = [],
  currentProductIndex = 0,
  handleAddProduct = () => { },
  handleProductSelect = () => { },
  handleCloneProduct = () => { },
  handleRemoveProduct = () => { },
  className,
  displayOnly = false,
  width = ''
}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    console.log("from model", productsData)
    if (productsData.length) {
      setData(productsData)
    } else {
      setData([]);
    }
  }, [productsData])

  return (
    <Container className={className} width={width}>
      {data.length === 0 ? (
        <NoItems className="mt-2">
          <div>There is no product available.</div>
          {!displayOnly &&
            <div>
              {" "}
              <span className="cursor-pointer" onClick={handleAddProduct}>
                <u>Create</u>
              </span>{" "}
              a new product or flag the adblock appropriately.
            </div>
          }
        </NoItems>
      ) : (
        <div className="d-flex">
          <ItemsList>
            {data.map((product, index) => (
              <Items
                key={index}
                className="d-flex flex-row"
                active={index === currentProductIndex}
                onClick={() => handleProductSelect(index)}
              >
                {!displayOnly && <>
                  <ProductSelectedBar
                    active={index === currentProductIndex}
                  ></ProductSelectedBar>
                  <ProductValidator valid={product.isValid}>
                    {product.isValid ? (
                      <FaCheckCircle />
                    ) : (
                      <FaMinusCircle />
                    )}
                  </ProductValidator>

                  {product.existingProduct ? <Type>
                    <FaInfoCircle />
                  </Type> : ''}
                </>
                }

                {product.brand.brand_name ? (
                  product.size_dimension_i === 2 ? (
                    <div className="flex-1">
                      {product.brand.brand_name} {product?.variant_id
                        ? product?.variant_nm ?
                          product?.variant_nm :
                          _.isObject(product?.variant)
                            ? product?.variant?.variety_nm
                            : '' : ''}{" "}
                      {product.hasOwnProperty('origin_nm') ?
                        product?.origin_nm
                        : _.isObject(product?.origin) ?
                          product?.origin.origin_nm : ''
                      } {product?.size_1}
                      {product?.size_2
                        ? "-" + product?.size_2 + " "
                        : ""}{" "}
                      {product?.ext_size_unit_i
                        ? sizeData.find(
                          (size) =>
                            size.id === product.ext_size_unit_i
                        )?.title
                        : ""}
                    </div>
                  ) : product.size_dimension_i === 4 ? (
                    <div className="flex-1">
                      {product.brand.brand_name} {product?.variant_id
                        ? product?.variant_nm ?
                          product?.variant_nm :
                          _.isObject(product?.variant)
                            ? product?.variant?.variety_nm
                            : '' : ''}{" "}
                      {product.hasOwnProperty('origin_nm') ?
                        product?.origin_nm
                        : _.isObject(product?.origin) ?
                          product?.origin.origin_nm : ''
                      }
                      {`${product?.size_1} x ${product?.size_2} `}
                      {product?.ext_size_unit_i
                        ? sizeData.find(
                          (size) =>
                            size.id === product.ext_size_unit_i
                        )?.title
                        : ""}
                    </div>
                  ) : product.size_dimension_i === 5 ? (
                    <div className="flex-1">
                      {product.brand.brand_name} {product?.variant_id
                        ? product?.variant_nm ?
                          product?.variant_nm :
                          _.isObject(product?.variant)
                            ? product?.variant?.variety_nm
                            : '' : ''}{" "}
                      {product.hasOwnProperty('origin_nm') ?
                        product?.origin_nm
                        : _.isObject(product?.origin) ?
                          product?.origin.origin_nm : ''
                      } {product?.size_1}
                      {product?.size_2
                        ? "-" + product?.size_2 + " "
                        : ""}{" "}
                      {product?.ext_size_unit_i
                        ? sizeData.find(
                          (size) =>
                            size.id === product.ext_size_unit_i
                        )?.title
                        : ""}{" "}
                      {product?.size_3 ? "of " + product?.size_3 : ""}
                      {product?.size_4
                        ? "-" + product?.size_4 + " "
                        : ""}{" "}
                      {product?.ext_size_unit_2_i
                        ? " " +
                        sizeData.find(
                          (size) =>
                            size.id === product?.ext_size_unit_2_i
                        )?.title
                        : ""}
                    </div>
                  ) : product.size_dimension_i === 6 ? (
                    <div className="flex-1">
                      {product.brand.brand_name} {product?.variant_id
                        ? product?.variant_nm ?
                          product?.variant_nm :
                          _.isObject(product?.variant)
                            ? product?.variant?.variety_nm
                            : '' : ''}{" "}
                      {product.hasOwnProperty('origin_nm') ?
                        product?.origin_nm
                        : _.isObject(product?.origin) ?
                          product?.origin.origin_nm : ''
                      } {product?.size_1}{" "}
                      {product?.ext_size_unit_i
                        ? sizeData.find(
                          (size) =>
                            size.id === product.ext_size_unit_i
                        )?.title
                        : ""}{" "}
                      {product?.size_2
                        ? "- " + product?.size_2 + " "
                        : ""}{" "}
                      {product?.ext_size_unit_2_i
                        ? " " +
                        sizeData.find(
                          (size) =>
                            size.id === product?.ext_size_unit_2_i
                        )?.title
                        : ""}
                    </div>
                  ) : product.size_dimension_i === 7 ? (
                    <div className="flex-1">
                      {product.brand.brand_name} {product?.variant_id
                        ? product?.variant_nm ?
                          product?.variant_nm :
                          _.isObject(product?.variant)
                            ? product?.variant?.variety_nm
                            : '' : ''}{" "}
                      {product.hasOwnProperty('origin_nm') ?
                        product?.origin_nm
                        : _.isObject(product?.origin) ?
                          product?.origin.origin_nm : ''
                      } {`${product?.size_1} `}
                      {product?.ext_size_unit_i
                        ? sizeData.find(
                          (size) =>
                            size.id === product.ext_size_unit_i
                        )?.title
                        : ""}
                      {` ${product?.size_2} `}
                      {product?.ext_size_unit_2_i
                        ? sizeData.find(
                          (size) =>
                            size.id === product.ext_size_unit_2_i
                        )?.title
                        : ""}
                    </div>
                  ) : product.size_dimension_i === 9 ? (
                    <div className="flex-1">
                      {product.brand.brand_name} {product?.variant_id
                        ? product?.variant_nm ?
                          product?.variant_nm :
                          _.isObject(product?.variant)
                            ? product?.variant?.variety_nm
                            : '' : ''}{" "}
                      {product.hasOwnProperty('origin_nm') ?
                        product?.origin_nm
                        : _.isObject(product?.origin) ?
                          product?.origin.origin_nm : ''
                      }
                      {product?.size_1}x{product?.size_2}{" "}
                      {product?.ext_size_unit_i
                        ? sizeData.find(
                          (size) =>
                            size.id === product.ext_size_unit_i
                        )?.title
                        : ""}
                      {" "}{product?.size_3}x{product?.size_4}{" "}
                      {product?.ext_size_unit_2_i
                        ? sizeData.find(
                          (size) =>
                            size.id === product.ext_size_unit_2_i
                        )?.title
                        : ""}
                    </div>
                  ) : product.size_dimension_i === 26 ? (
                    <div className="flex-1">
                      {product.brand.brand_name} {product?.variant_id
                        ? product?.variant_nm ?
                          product?.variant_nm :
                          _.isObject(product?.variant)
                            ? product?.variant?.variety_nm
                            : '' : ''}{" "}
                      {product.hasOwnProperty('origin_nm') ?
                        product?.origin_nm
                        : _.isObject(product?.origin) ?
                          product?.origin.origin_nm : ''
                      } {product?.size_1}
                      {" "}
                      {product?.ext_size_unit_i
                        ? sizeData.find(
                          (size) =>
                            size.id === product.ext_size_unit_i
                        )?.title
                        : ""}
                      {(product?.size_3 ? `of ${product.size_3}` : '') +
                        (product?.size_4 ? ` - ${product.size_4}` : '')}
                      {product?.ext_size_unit_2_i
                        ? " " +
                        sizeData.find(
                          (size) =>
                            size.id === product?.ext_size_unit_2_i
                        )?.title
                        : ""}
                    </div>
                  ) : (
                    <div className="flex-1">
                      {product.brand.brand_name} {product?.variant_id
                        ? product?.variant_nm ?
                          product?.variant_nm :
                          _.isObject(product?.variant)
                            ? product?.variant?.variety_nm
                            : '' : ''}{" "}
                      {product.hasOwnProperty('origin_nm') ?
                        product?.origin_nm
                        : _.isObject(product?.origin) ?
                          product?.origin.origin_nm : ''
                      } {product?.size_1}{" "}
                      {product?.ext_size_unit_i
                        ? sizeData.find(
                          (size) =>
                            size.id === product.ext_size_unit_i
                        )?.title
                        : ""}
                    </div>
                  )
                ) : (
                  <div className="flex-1">{`Unbranded Product ${index + 1
                    }`}</div>
                )}
                {!displayOnly && <>
                  <ButtonIcon
                    active={data.length > 0}
                    onClick={(event) => {
                      handleCloneProduct(event, product?.index);
                      postPageEvents(
                        "click",
                        `Clone Product ${product?.index}`
                      );
                    }}
                  >
                    <FaClone />
                  </ButtonIcon>

                  <ButtonIcon
                    active={data.length > 0}
                    onClick={(event) => {
                      handleRemoveProduct(event, product?.index);
                      postPageEvents(
                        "click",
                        `Remove Product ${product?.index}`
                      );
                    }}
                  >
                    <FaTrash />
                  </ButtonIcon>
                </>
                }
              </Items>
            ))}
          </ItemsList>
        </div>
      )}
    </Container>
  );
};



const Container = styled.div`
  /* width: ${(props) => props.width === 'small' ? '80%' : '100%'}; */
  overflow: auto;
  max-height: ${(props) => props.width === 'small' ? '90px' : '100%'};
`;

const NoItems = styled.div`
  font-size: 14px;
`;

const ItemsList = styled.div`
  width: 100%;
  overflow: auto;
  /* max-height: ${(props) => props.width === 'small' ? '100px' : '200px'}; */
  ::-webkit-scrollbar {
    width: 14px;
    background: ${(props) => props.theme.palette.backgrounds};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.backgrounds};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;

const Items = styled.div`
  border: 1px solid
    ${(props) =>
    props.active
      ? props.theme.palette.primaryOutline
      : props.theme.palette.backgrounds};
  width: 100%;
  cursor: pointer;
  div {
    background-color: ${(props) => props.theme.palette.items};
    margin: 0 1px;
    padding: 6px 10px;
  }
`;

const ProductSelectedBar = styled.div`
  width: 4px;
  padding: 0 !important;
  margin: 0 !important;
  background-color: ${(props) =>
    props.active
      ? props.theme.palette.primaryOutline
      : props.theme.palette.backgrounds} !important;
`;


const ProductValidator = styled.div`
  color: ${(props) =>
    props.valid ? props.theme.palette.success : props.theme.palette.error};
`;


const Type = styled.div`
  color: white;
`;


const ButtonIcon = styled.div`
  color: ${(props) => (props.active ? "white" : "#333")};
  background-color: ${(props) => props.theme.palette.items};
  transition: all 300ms ease;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;