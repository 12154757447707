/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import { Input2, DropDown2 } from "components";
import { useInput } from "hooks";
import AutocompleteFreeText from "components/AutocompleteFreeText";
import { BOGO_OFFER_PREDICTIVE_TEXT } from "config";
export const OfferBOGOTextBuilder = ({
  className,
  value,
  onChange = () => {},
}) => {
  const buy = useInput("1");
  const get = useInput("1");
  const val = useInput("");
  const type = useInput("Free");
  const typeData = [
    { id: "%", title: "%" },
    { id: "% off", title: "% off" },
    { id: "$", title: "$" },
    { id: "$ off", title: "$ off" },
    { id: "Free", title: "Free" },
  ];
  const freeText = useInput("");

  const handleUpdateValue = (b, g, v, t, free_text) => {
    switch (t) {
      case "Free":
        onChange(`B${b}G${g}F ${free_text}`);
        get?.setValue(g)
        val.setValue("");
        type.setValue("Free")
        break;
      case "%":
        onChange(`B${b}G${g} ${v}% ${free_text}`);
        get?.setValue(g)
        type.setValue("%")
        break;
      case "% off":
        onChange(`B${b}G${g} ${v}% off ${free_text}`);
        get?.setValue(g)
        type.setValue("% off")
        break;
      case "$":
        onChange(`B${b}G${g} $${v} ${free_text}`);
        get?.setValue(g)
        type.setValue("$")
        break;
      case "$ off":
        onChange(`B${b}G${g} $${v} off ${free_text}`);
        get?.setValue(g)
        type.setValue("$ off")
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // if (value) {
    //   const parseBOGO = (str) => {
    //     console.log(str)
    //     const gIndex = str.indexOf("G");
    //     const b = str.substring(1, gIndex);
    //     const g = str.substring(gIndex + 1);
    //     return { b, g };
    //   };
    //   const ft = value.indexOf("w/");
    //   let v = value.slice(0, ft - 1);
    //   if (v.endsWith("F")) {
    //     console.log("endsWithF")
    //     type.setValue("Free");
    //     console.log("here")
    //     const { b, g } = parseBOGO(value.substring(0, ft > 0 ? ft : value.length - 1));
    //     buy.setValue(b);
    //     get.setValue(g.trim());
    //     console.log(g)
    //     freeText.setValue(value.slice(ft));
    //   } else if (v.endsWith("%")) {
    //     console.log("endsWith%")
    //     const parts = value.substring(0, value.length - 1).split(" ");
    //     const { b, g } = parseBOGO(parts[0]);
    //     buy.setValue(b);
    //     get.setValue(g?.replace("F", ""));
    //     val.setValue(parts[1].replace("%", ""));
    //     freeText.setValue(value.slice(ft));
    //     type.setValue("%");
    //   } else if (v.endsWith("% off")) {
    //     console.log("endsWith%OFF")
    //     const parts = value.substring(0, value.length - 5).split(" ");
    //     console.log(parts)
    //     const { b, g } = parseBOGO(parts[0]);
    //     buy.setValue(b);
    //     get.setValue(g?.replace("F", ""));
    //     val.setValue(parts[1].replace("%", ""));
    //     freeText.setValue(value.slice(ft));
    //     type.setValue("% off");
    //   } else if (v.endsWith(" off")) {
    //     console.log("endsWithOFF")
    //     const parts = value.substring(0, value.length - 4).split(" ");
    //     const { b, g } = parseBOGO(parts[0]);
    //     buy.setValue(b);
    //     get.setValue(g?.replace("F", ""));
    //     val.setValue(parts[1].substring(1).replace("%", ""));
    //     freeText.setValue(value.slice(ft));
    //     type.setValue("$ off");
    //   } else {
    //     console.log("else")
    //     const parts = value.split(" ");
    //     if (parts.length === 2) {
    //       const { b, g } = parseBOGO(parts[0]);
    //       buy.setValue(b);
    //       get.setValue(g?.replace("F", ""));
    //       val.setValue(parts[1].substring(1));
    //       type.setValue("$");
    //       console.log("us")
    //     } else if (parts.length === 3) {
    //       console.log('issue')
    //       const { b, g } = parseBOGO(parts[0]);
    //       buy.setValue(b);
    //       get.setValue(g?.replace("F", ""));
    //       val.setValue(parts[1].substring(1));        
    //       freeText.setValue(value.slice(ft));
    //       type.setValue("$");
    //     }
    //   }
    // }
    if (value) {
      let [bogo, valueType, ...text] = value.split(" ");
      text = text.join(" ");
      let v = ''
      let t = 'Free'
      // Set BOGO Values
      const getIndex = bogo.indexOf('G');
      if (getIndex) {
        let buyVal = bogo.slice(1, getIndex);
        let getVal = bogo.slice(getIndex + 1, bogo.length);
        if (getVal.endsWith('F')){
          t = 'Free'
          getVal = getVal.replace('F', '')
        }
        buy.setValue(buyVal);
        get.setValue(getVal);
      }
      // Set Value, type and Free Text
      if (valueType) {
        if (valueType.endsWith('%')) {
          if (text.startsWith('off')) {
            v = valueType.replace('%', '');
            text = text.replace('off', '').trim();
            t = '% off'
          }
          else {
            v = valueType.replace('%', '');
            t = '%';
          }
        }
        else if (valueType.endsWith('off')) {
          v = valueType.replace('off', '');
          v = valueType.replace('$', '');
          t = 'off'
        }
        else if (valueType.startsWith('$')) {
          if (text.startsWith('off')) {
            v = valueType.replace('off', '');
            v = valueType.replace('$', '');
            text = text.replace('off', '').trim();
            t = '$ off'
          } else {
            v = valueType.replace('$', '')
            t = '$'
          }
        }
        val.setValue(v);
        type.setValue(t);
        if (t === 'Free' && !text) {
          if (valueType) {
            freeText.setValue(valueType)
          }
        }
        if (text && text.length > 0) {
          freeText.setValue(text)
        }
      }
    }
    return () => { };
  }, [value]);

  return (
    <div className={`${className} mt-1 p-2`}>
      <Title>Offer Text Builder</Title>
      <Container className="mt-2">
        <div style={{ width: 60, marginRight: 20 }}>
          <Input2
            label="Buy"
            value={buy.value}
            onChange={(e) => {
              buy.setValue(e);
              handleUpdateValue(e, get.value, val.value, type.value, freeText.value);
            }}
          />
        </div>
        <div style={{ width: 60, marginRight: 20 }}>
          <Input2
            label="Get"
            value={get.value}
            onChange={(e) => {
              get.setValue(e);
              handleUpdateValue(buy.value, e, val.value, type.value, freeText.value);
            }}
          />
        </div>

        <div style={{ width: 120, marginRight: 20 }}>
          <Input2
            label="Value"
            value={val.value}
            onChange={(e) => {
              if (type.value !== 'Free'){
                val.setValue(e);
                handleUpdateValue(buy.value, get.value, e, type.value, freeText.value);
              }
            }}
          />
        </div>

        <div className="ml-auto" style={{ width: 120 }}>
          <DropDown2
            label="Type"
            data={typeData}
            width={120}
            value={type.value}
            onChange={(e) => {
              type.setValue(e);
              console.log(buy.value, get.value, val.value, e, freeText.value);
              handleUpdateValue(buy.value, get.value, val.value, e, freeText.value);
            }}
          />
        </div>
      </Container>
      <Container>
      <AutocompleteFreeText
        data={BOGO_OFFER_PREDICTIVE_TEXT}
        value={freeText.value}
        onChange={e => {
          freeText.setValue(e)
          handleUpdateValue(buy.value, get.value, val.value, type.value, e);
        }}
        label='Free Text'
      />
      </Container>
    </div>
  );
};

const Title = styled.div`
  color: #66c0e1;
  font-size: 16px;
`;
const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  padding: 8px;
  display: flex;
`;
