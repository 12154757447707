import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import {
  Card,
  ScrollView,
  OfferTypeSearch,
  InfoInput,
  InfoCheckBox,
  OfferInfoContainer,
} from "components";
import { INIT_OFFER_TEXT } from "config";
import { useMainContext } from "contexts";

export const OfferInfo = ({
  isEmpty,
  offerTypeData = [],
  useDefaultSaveOfferText = true,
  isLinerDisabled = false
}) => {
  const { currentInfo, isManualEntry, updateInfos } = useMainContext();
  const executedOnce = useRef(false);

  useEffect(() => {
    if (!executedOnce.current && currentInfo && currentInfo.offer_text && currentInfo.offer_type === 'Save') {
      const wPurchaseMatch = currentInfo.offer_text.match(/w\/purchase\/(\$\d+(\.\d+)?|\d+)/i)
      if (wPurchaseMatch) {
        executedOnce.current = true
        updateInfos({
          min_qty_dollar_f: true,
          min_qty: wPurchaseMatch ? wPurchaseMatch[1] : ""
        })
      }
    }
  })
  
  
  const handleOfferTypeChange = (params) => {
    if (params && isManualEntry) {
      if (((params.toLowerCase() === 'save') && useDefaultSaveOfferText) || params.toLowerCase() !== 'save') {
        setTimeout(() => {
          updateInfos({
            offer_text: INIT_OFFER_TEXT[params.toLowerCase()],
            offer_type: params,
          });
        }, 300);
      }
    }
  };
  return (
    <InfoSection title="OFFER INFORMATION" className="flex-1 flex-column">
      {isEmpty ? (
        <div className="mt-4 pt-4 text-center">No offers available</div>
      ) : (
        <ScrollView>
          <form autoComplete="off">
            <input style={{ display: "none" }} autoComplete="false"></input>
            <OfferInfoContainer inputId="offer_type">
              <OfferTypeSearch
                label="Offer Type"
                inputId="offer_type"
                data={offerTypeData}
                disabled={!isManualEntry}
                onChange={handleOfferTypeChange}
              />
            </OfferInfoContainer>
            <InfoInput
              className="mt-3"
              label="Offer Text"
              inputId="offer_text"
            />
            <div className="d-flex mt-3">
              <InfoInput label="Reg. Price Start" inputId="reg_price" />
              <InfoInput label="Reg. Price End" inputId="reg_price2_d" />
              <InfoInput label="Limit" inputId="limit" />
            </div>
            <InfoCheckBox
              className="mt-3"
              inputId="min_qty_dollar_f"
              label="Does the offer have a minimum qualifier?"
            />
            {currentInfo?.min_qty_dollar_f && (
              <InfoInput
                className="mt-2 ml-4 pl-2"
                inputId="min_qty"
                label="Minimum qualifier amount"
              />
            )}
            <InfoCheckBox
              className="mt-3"
              inputId="must_buy_f"
              label="Does the offer have must buy amounts?"
            />
            <InfoCheckBox
              className="mt-3"
              inputId="mix_and_match_f"
              label="Does the offer allow mix and match?"
            />
            <InfoCheckBox
              className="mt-3"
              inputId="include_in_net_cost_f"
              label="Does the offer include net cost?"
            />
            {!isLinerDisabled &&
              <InfoCheckBox
                className="mt-3"
                inputId="liner_f"
                label="Does the offer have a liner?"
              />
            }
          </form>
        </ScrollView>
      )}
    </InfoSection>
  );
};

const InfoSection = styled(props => <Card {...props}/>)`
  height: calc(100% - 4px);
  flex: 1;
  color: white;
  width: 380px;
  .offer-label {
    color: white;
    font-size: 24px;
  }
`;
