/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { MainLayout } from "layouts";
import { PAGE_TYPES } from "config";
import {
  Button,
  Card,
  OfferSelector,
  AdblockLocation,
  AdblockImage,
  AdblockApproved,
  ManualEntry,
  EventsDropDown,
  OfferTypeSearch,
  QuickSelectionChoice,
  OfferItem,
  OfferTextBuilder,
  NoSuggestions,
  IncentiveItem,
  QuickSelection,
  MetaData,
  FlagButtonGroup,
  EmptyOfferSelector,
  IncentiveTextBuilder,
  HistoryDropdown,
} from "components";
import { INIT_OFFER_TEXT } from "config";
import { IncentiveInfo, OfferInfo, EmptyInfo } from "fragments";
import { format } from "date-fns";
import {
  getNextOffer,
  getImage,
  approveOffer,
  getOfferTypesApi,
  updateAdblockSuggestions,
  skipTopDownAdblock,
  doNotEnterTopDownAdblock,
  getCircularIncentives,
  getPageAdblocks,
  rejectAdblock,
  getSimilarAdBlockOffers,
  getCurrentUserAPI,
  saveCircularIncentives,
  savePageIncentives,
  getProductSizesApi
} from "api";
import { useMainContext, useAdblockHistoryContext } from "contexts";
import { useInput, useModal } from "hooks";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { omit } from "lodash";
import { useLocation } from "@reach/router";
import useKeypress from "react-use-keypress";
import {
  OFFER_SUGGESTION_TITLE,
  INCENTIVE_SUGGESTION_TITLE,
  OFFER_INIT_SUGGESTIONS,
  OFFER_INIT_INFO,
  INCENTIVE_INIT_SUGGESTION,
  INCENTIVE_INIT_INFO,
  INCENTIVE_INFO_FIELDS,
  OFFER_INFO_FIELDS,
  INIT_OFFER_METADATA,
  EXTRA_INCENTIVE_SUGGESTIONS,
} from "config";
import { validateOffer, validateIncentive, extractTextBetweenFirstBalancedParenthesis, setCachedImage, getCachedImage, offerValidationSchema, getIncentiveInfo } from "utils";
import { ImageMatchingModal } from "modals";
import { BsImageAlt } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import { updateOffersBasedOnDigitalCoupon } from "utils/offers";

export const OffersPage = () => {
  //const params = useParams();
  const blockRef = useRef();
  const location = useLocation();

  const {
    currentCircularId,
    currentPageId,
    currentAdBlockId,
    setCurrentCircularId,
    setCurrentPageId,
    setCurrentAdBlockId,
    setCurrentPage,
    setPreviousTime,
    postPageEvents,
    activeField,
    setActiveField,
    setFieldNames,
    moveNextField,
    updateActiveInfo,
    currentInfo,
    setCurrentInfo,
    setSessionId,
    activeFieldValue,
    updateInfo,
    setMetaData,
    setMetaDataFromPayload,
    metaData,
    updateMetaData,
    isManualEntry,
    setIsManualEntry,
    movePreviousField,
    getMetaData,
    updateInfos,
    setCurrentCircularDate,
    setCurrentCircularStartDate,
    updateLimitedTimeDates,
    setLiveCounterData
  } = useMainContext();
  const { initHistory, pushHistory } = useAdblockHistoryContext();

  //Loading States
  const [isLoading, setIsLoading] = useState(true);
  const [suggestionsLoading, setSuggestionsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [copiedText, setCopiedText] = useState(false);

  //For the skeleton partial
  const [imageHeight, setImageHeight] = useState(false);
  const [imageWidth, setImageWidth] = useState(false);

  //Base List Data
  const [adblockData, setAdblockData] = useState([]);
  const [offerTypeData, setOfferTypeData] = useState([]);
  const [productData, setProductData] = useState([])
  const [sizeData, setSizeData] = useState([]);

  //Currently Viewed Information
  const [circularName, setCircularName] = useState("");
  const [circularType, setCircularType] = useState("");
  const [pageType, setpageType] = useState("");
  const [queueCount, setQueueCount] = useState("");
  const [messageID, setMessageID] = useState(false);
  const [pageImg, setPageImg] = useState("");
  const [adblockFilename, setAdblockFilename] = useState("");

  //Offer list from adblock
  const [offerData, setOfferData] = useState([]);
  const currentOfferIndex = useInput(0);
  const [allOffersValid, setAllOffersValid] = useState(false);
  const [offerSuggestionData, setOfferSuggestionData] = useState(
    OFFER_INIT_SUGGESTIONS
  );

  //Adblock Flags
  const [isApproved, setIsApproved] = useState(false);
  const [isRejected, setIsRejected] = useState(false);
  const [isSkipped, setIsSkipped] = useState(false);
  const [isDoNotEnter, setIsDoNotEnter] = useState(false);
  const [split, setSplit] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true)
  const [isOfferAndIncentiveEmpty, setIsOfferAndIncentiveEmpty] = useState(true)
  const [isImported, setIsImported] = useState(false)

  //Incentives list from adblock
  let [incentiveData, setIncentiveData] = useState([]);
  const currentIncentiveIndex = useInput(0);
  const [incentiveSuggestionData, setIncentiveSuggestionData] = useState(
    INCENTIVE_INIT_SUGGESTION
  );

  const [currentItemType, setCurrentItemType] = useState("offer");
  // Page Data
  const [pageWidth, setPageWidth] = useState(1);
  const [pageHeight, setPageHeight] = useState(1);
  const [isLinerDisabled, setIsLinerDisabled] = useState(false);

  //Adblock data
  const events = useInput([]);
  const [adblockCoords, setAdblockCoords] = useState({
    coords_x1: 0,
    coords_x2: 0,
    coords_y1: 0,
    coords_y2: 0,
  });
  const [startTime, setStartTime] = useState('');

  //Form Flow
  const [activeOfferInfo, setActiveOfferInfo] = useState("");
  const [activeOfferInfoText, setActiveOfferInfoText] = useState("");
  //const [inputFocused, //setInputFocused] = useState(false);

  //Suggestions
  const [currentSuggestions, setCurrentSuggestions] = useState([]);

  const [isEndQueue, setIsEndQueue] = useState(false);
  const [useDefaultSaveOfferText, setUseDefaultSaveOfferText] = useState(true);
  const [firstDefaultSaveOfferText, setFirstDefaultSaveOfferText] = useState('');

  // Flag of Current Page's digital_coupon_f
  const [digitalCouponApplied, setDigitalCouponApplied] = useState(false);
  const digitalCouponAppliedRef = useRef(digitalCouponApplied);
  
  // Modals
  const imageMatchingModal = useModal();
  /*
  ///////////////////////////////////////
  ////// Start View Methods ////////////
  /////////////////////////////////////
  */

  const init = async () => {
    setCurrentPage("TD - Offers");
    setPreviousTime(new Date());
    setIsLoading(true);

    setImageHeight(blockRef.current.offsetHeight);
    setImageWidth(blockRef.current.offsetWidth);
    setMetaData(INIT_OFFER_METADATA);

    const data = await getOfferTypesApi();
    setOfferTypeData(
      data.payload.map((item) => ({
        ...item,
        id: item.name,
        title: item.name,
      }))
    );
    setOfferSuggestionData({
      ...offerSuggestionData,
      offer_type: data.payload
        .sort((a, b) => a.ext_id - b.ext_id)
        .slice(0, 9)
        .map((item) => {
          return {
            title: item.name,
            value: item.name,
          };
        }),
    });

    fetchAdBlock();
    
    const res = await getProductSizesApi();
    setSizeData(res.payload.map((item) => ({ id: item.ext_id, title: item.size_c })));

    setIsLoading(false);
  };

  const initInfo = () => {
    setActiveField("");
    setMetaData(INIT_OFFER_METADATA);
  };

  /*
  ///////////////////////////////////////
  ////// Watchers /////////////////////
  /////////////////////////////////////
  */

  useEffect(() => {
    init();
    initHistory("offer");
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      isManualEntry &&
      activeOfferInfo !== "" &&
      activeOfferInfo.indexOf("_f") === -1 &&
      activeOfferInfo !== "offer_type"
    ) {
      //Setting the timeout prevents the keypress from populating an input
      setTimeout(() => {
        document.getElementById(activeOfferInfo).focus();
      });
    }
    return () => {};
  }, [isManualEntry]);

  useEffect(() => {
    setAllOffersValid(
      offerData.every(function (e) {
        // eslint-disable-next-line
        return e?.offer_type === "Price ea" ? (e.reg_price && setIsSubmitDisabled(!(parseFloat(e.reg_price?.toString().replace(/[^0-9\.]+/g, "")) >= parseFloat(e.offer_text.split('-')[0]?.toString().replace(/[^0-9\.]+/g, ""))))) : e?.isValid;
      }) &&
      incentiveData.every(function (e) {
        return e.isValid;
      })
    );
    return () => { };
  }, [offerData, incentiveData]); 

  useEffect(() => {
    let disableApprove = !offerData.length && !incentiveData.length;
    if (metaData.length > 1){
      disableApprove = disableApprove && !metaData[1].value; // Checking if Brand Equity is selected
    }
    setIsOfferAndIncentiveEmpty(disableApprove);
    return () => {};
  }, [offerData, incentiveData, metaData]);

  useEffect(() => {
    if (circularType === 'Training Circular'){
      setCurrentSuggestions([]);
    } else {
    if (activeField && currentItemType) {
      setCurrentSuggestions(
        currentItemType === "offer"
          ? offerSuggestionData[activeField]
          : incentiveSuggestionData[activeField]
      );
      if (
        currentInfo["offer_type"] === "w/purchase case" &&
        activeField === "offer_text"
      ) {
        setCurrentSuggestions([
          {
            value: "w/purchase whole case",
            title: "w/purchase whole case",
          },
          {
            value: "w/purchase half case",
            title: "w/purchase half case",
          },
        ]);
      } else if (
        currentInfo["offer_type"] === "w/purchase product" &&
        activeField === "offer_text"
      ) {
        let products = [];
        if (adblockData?.products && adblockData?.products.length > 0) {
          products = adblockData?.products.map((p) => ({
            title: p.brand_nm,
            value: p.brand_nm,
          }));
        } else if (
          adblockData?.suggestions?.products &&
          adblockData?.suggestions?.products.length > 0
        ) {
          products = adblockData?.suggestions?.products.map((p) => ({
            title: p.brand_nm,
            value: p.brand_nm,
          }));
        }
        setCurrentSuggestions(products);
      } else if (
        [
          "Clearance",
          "Extra Coupons",
          "Free Shipping",
          "Pharmacy Services",
          "Rollback",
          "Saturdate",
          "Sweepstakes",
          "Earn Free Item",
          "w/agreement",
        ].includes(currentInfo["incentive_type"]) &&
        activeField === "incentive_text"
      ) {
        setCurrentSuggestions(
          EXTRA_INCENTIVE_SUGGESTIONS[currentInfo["incentive_type"]]
        );
      }
      setActiveOfferInfoText(
        currentItemType === "offer"
          ? OFFER_SUGGESTION_TITLE[activeField]
          : INCENTIVE_SUGGESTION_TITLE[activeField]
      );
    }
    }

    return () => {};
  }, [activeField]);

  useEffect(() => {
    if (Object.keys(currentInfo).length > 0) {
      if (currentItemType === "offer") {
        let temp = [...offerData];
        temp[currentOfferIndex.value] = {
          ...currentInfo,
          isValid: validateOffer(currentInfo),
        };
        setOfferData(temp);
      } else if (currentItemType === "incentive") {
        let temp = [...incentiveData];
        setIncentiveData(
          temp.map((item) => {
            if (item.index === currentInfo.index)
              return {
                ...currentInfo,
                isValid: validateIncentive(currentInfo),
              };
            return { ...item };
          })
        );
      }
    }
    return () => {};
  }, [currentInfo]);

  useEffect(() => {
    let firstOffer = [];
    if (offerData) {
      firstOffer = offerData?.filter(item =>
        item.offer_type.toLowerCase() === 'price ea' || item.offer_type.toLowerCase() === 'bogo' ||
        item.offer_type.toLowerCase() === '& up' || item.offer_type.toLowerCase() === 'x for' ||
        item.offer_type.toLowerCase() === 'w/purchase/$' || item.offer_type.toLowerCase() === 'w/purchase/qty' ||
        item.offer_type.toLowerCase() === 'w/purchase/qty/$')
    }
    setUseDefaultSaveOfferText(false ? firstOffer.length > 0 : true);
    if (firstOffer.length > 0) {
      setUseDefaultSaveOfferText(false);
      let maxVal = 0;
      if (offerData && !currentInfo?.offer_text) {
        let findPriceEa = offerData.find(item => item.offer_type.toLowerCase() === "price ea");
        let findOffers = '';
        if (findPriceEa) {
          findPriceEa = findPriceEa.offer_text;
          let val = findPriceEa.split("-")
          // eslint-disable-next-line
          let numbers = val.map(item => item.replace(/[^\d\.]*/g, ''));
          maxVal = Math.max(...numbers)
        } else {
          findOffers = offerData.filter(item => item.offer_type.toLowerCase() !== "save")[0];
          if (findOffers) {
            if (findOffers.offer_type.toLowerCase() === 'bogo') {

            } else if (findOffers.offer_type.toLowerCase() === '& up') {
              maxVal = findOffers.offer_text.substring(1).split(' ')[0];
            } else if (findOffers.offer_type.toLowerCase() === 'x for') {
              maxVal = findOffers.offer_text.split('$')[1];
            } else if (findOffers.offer_type.toLowerCase() === 'w/purchase/$') {
              const reg = /w\/purchase\/\$(\d+(?:\.\d+)?)(?:-(\d+(?:\.\d+)?))?/;
              const match = findOffers.offer_text.match(reg);
              if (match) {
                const first = match[1];
                const second = match[2];
                if (first) {
                  if (second) {
                    maxVal = Math.max(first, second);
                  } else {
                    maxVal = first;
                  }
                }
              }
            } else if (findOffers.offer_type.toLowerCase() === 'w/purchase/qty') {
              const reg = /w\/purchase\/(\d+(?:\.\d+)?)(?:-(\d+(?:\.\d+)?))?/;
              const match = findOffers.offer_text.match(reg);
              if (match) {
                const first = match[1];
                const second = match[2];
                if (first) {
                  if (second) {
                    maxVal = Math.max(first, second);
                  } else {
                    maxVal = first;
                  }
                }
              }
            } else if (findOffers.offer_type.toLowerCase() === 'w/purchase/qty/$') {
              maxVal = findOffers.offer_text.split('$')[1];
            }
          }
        }
      }
      if (maxVal) {
        setFirstDefaultSaveOfferText(maxVal);
      }
    } else {
      setUseDefaultSaveOfferText(true);
    }
    return () => {};
  }, [offerData])

  useEffect(()=> {
    if (productData){
      let linerExists = productData.find(item => item.liner_f === true);
      if (linerExists){
        setIsLinerDisabled(false);
      } else {
        setIsLinerDisabled(true);
      }
    }
  }, [productData])

  useEffect(() => {
    if (adblockData.similar_adblock && !adblockData.has_children && !adblockData.offers_approved) {
      imageMatchingModal.openModal()
    }
  }, [adblockData])

  useEffect(() => {
    digitalCouponAppliedRef.current = digitalCouponApplied;
  }, [digitalCouponApplied])

  /*
  ///////////////////////////////////////
  ////// Fetch Main Data ////////////////
  /////////////////////////////////////
  */

  const fetchAdBlock = async () => {
    setIsFetching(true);
    setIsManualEntry(false);
    setIsImported(false);
    setIsApproved(false);
    setIsRejected(false);
    setIsDoNotEnter(false);
    setIsSkipped(false);

    setOfferData([]);
    setProductData([]);
    setIncentiveData([]);
    initInfo();
    const res = await getCurrentUserAPI();
    if (res.success) {
        setLiveCounterData(res.results);
    }
    const result = await getNextOffer(currentAdBlockId);

    if (result.success) {
      setStartTime(new Date().toISOString().replace(',',''));
      //When payload comes back as 204 it will be a blank string
      if (result.payload !== "") {
        let updateOfferSggs4DigitalCoupon = false;
        setIsEndQueue(false);
        setAdblockData(result.payload.adblock);
        setCurrentAdBlockId(result.payload.adblock.uuid);
        setCurrentPageId(result.payload.page.uuid);
        setDigitalCouponApplied(Boolean(result.payload.page.digital_coupon_f))
        setCurrentCircularId(result.payload.circular.uuid);

        const page_type = PAGE_TYPES.find((item) => item.page_c === result.payload.page.page_loc);
        setpageType(page_type.page_nm);

        setSplit(result.payload.adblock.split > 0 ? true : false);
        console.log("push", {
          circularId: result.payload.circular.uuid,
          pageId: result.payload.page.uuid,
          adblockId: result.payload.adblock.uuid,
        });
        pushHistory({
          circularId: result.payload.circular.uuid,
          pageId: result.payload.page.uuid,
          adblockId: result.payload.adblock.uuid,
        });
        setCircularName(
          result.payload.circular.retailer.name +
            "_" +
            format(new Date(result.payload.circular.circ_week_dt.replace(/-/g, '/').replace(/T.+/, '')), "MM/dd/yyyy")
            + `_${result.payload.circular.ext_id}`
            + `_${result.payload.circular.circ_version ? result.payload.circular.circ_version : 0}`
        ); //setPageData(result.payload.page);
        setCircularType(result.payload.circular.circ_type);
        if (result.payload.circ_type === 'Training Circular'){
          setCurrentSuggestions([]);
        }
        setQueueCount(result.payload.queue_count);
        setMessageID(result.payload.message_id);
        setSessionId(result.payload.message_id);
        setIsDoNotEnter(result.payload.adblock?.do_not_enter_f);
        setIsSkipped(result.payload.adblock?.skipped);
        setIsRejected(result.payload.adblock?.offers_rejected);
        setIsApproved(result.payload.adblock?.offers_approved);
        setCurrentCircularDate(format(new Date(result.payload.circular.circ_week_dt.replace(/-/g, '/').replace(/T.+/, '')), "MM-dd-yyyy"));
        setCurrentCircularStartDate(format(new Date(result.payload.circular.start_dt?.replace(/-/g, '/').replace(/T.+/, '')), "MM-dd-yyyy"));

        setAdblockCoords({
          coords_x1: result.payload.adblock.coords_x1,
          coords_x2: result.payload.adblock.coords_x2,
          coords_y1: result.payload.adblock.coords_y1,
          coords_y2: result.payload.adblock.coords_y2,
        });
        let liner_disabled = false
        if (result.payload.adblock.products){
          liner_disabled = !result.payload.adblock.products?.find(item => item.liner_f === true);
        }
        let tempIncentives = [];
        if (result.payload.adblock?.incentive_text) {
          getIncentiveInfo(result.payload.adblock?.incentive_text).forEach(
            incentives => {
              const newIncentive = {
                incentive_type: incentives.incentive_type,
                incentive_text: incentives.incentive_text,
                index: tempIncentives.length > 0 ? tempIncentives[tempIncentives.length - 1].index + 1 : 0,
                isValid: true,
                incentive_attach_to: 'Adblock',
                incentive_limit: incentives.limit,
                incentive_include_net_cost: incentives.net_cost
              };
              tempIncentives = [...tempIncentives, newIncentive];
            })
        }
        if (result.payload.page?.incentive_nm) {
          getIncentiveInfo(result.payload.page?.incentive_nm).forEach(
            incentives => {
              const newIncentive = {
                incentive_type: incentives.incentive_type,
                incentive_text: incentives.incentive_text,
                index: tempIncentives.length > 0 ? tempIncentives[tempIncentives.length - 1].index + 1 : 0,
                isValid: true,
                incentive_attach_to: 'Page',
                incentive_limit: incentives.limit,
                incentive_include_net_cost: incentives.net_cost
              };
              tempIncentives = [...tempIncentives, newIncentive];
            })
        }
        if (result.payload.circular?.incentive_nm) {
          getIncentiveInfo(result.payload.circular?.incentive_nm).forEach(
            incentives => {
              const newIncentive = {
                incentive_type: incentives.incentive_type,
                incentive_text: incentives.incentive_text,
                index: tempIncentives.length > 0 ? tempIncentives[tempIncentives.length - 1].index + 1 : 0,
                isValid: true,
                incentive_attach_to: 'Circular',
                incentive_limit: incentives.limit,
                incentive_include_net_cost: incentives.net_cost,
                existingIncentive: true
              };
              tempIncentives = [...tempIncentives, newIncentive];
            })
        }
        
        const existingSuggestion = await getSimilarAdBlockOffers(result?.payload.adblock.uuid);
        let existingOfferSuggestion = {}
        if (existingSuggestion.success) {
          existingOfferSuggestion = existingSuggestion.payload;
        }
        if (
          !result.payload?.offers_approved &&
          existingOfferSuggestion &&
          existingOfferSuggestion?.incentives) {
          if (existingOfferSuggestion?.incentives.length > 0) {
            const existingIncent = existingOfferSuggestion?.incentives.toString()
            getIncentiveInfo(existingIncent).forEach(
              incentives => {
                const newIncentive = {
                  incentive_type: incentives.incentive_type,
                  incentive_text: incentives.incentive_text,
                  index: tempIncentives.length > 0 ? tempIncentives[tempIncentives.length - 1].index + 1 : 0,
                  isValid: true,
                  incentive_attach_to: 'Adblock',
                  incentive_limit: incentives.limit,
                  incentive_include_net_cost: incentives.net_cost,
                  existingIncentive: true
                };
                tempIncentives = [...tempIncentives, newIncentive];
              })
          }
        }
        
        const cirIncentiveData = await getCircularIncentives(
          result.payload.circular.uuid
        );
        setIncentiveSuggestionData({
          ...incentiveSuggestionData,
          incentive_text: cirIncentiveData.map((item) => ({
            title: item,
            value: item,
          })),
        });
        setPageWidth(result.payload.page.img_width);
        setPageHeight(result.payload.page.img_height);

        let eventsList = [];
        if (result.payload.offers_approved) {
          eventsList = result.payload.adblock?.event_nm ? result.payload.adblock?.event_nm.split(",") : [];
        } else {
          if (result.payload.adblock?.group !== null && result.payload.adblock?.group.event_type) {
            eventsList = result.payload.adblock?.group.event_text.split(',');
          }
        }
        events.setValue(eventsList);

        let filename = result.payload.page.filename;
        if(result.payload.page.is_doubletruck) {
          filename = result.payload.page.doubletruck_filename;
        }

        let backImg;
        const cachedImage = await getCachedImage(filename);
        if (!cachedImage) {
          backImg = await getImage(filename);
          if (backImg.success)
            await setCachedImage(filename, backImg.payload);
        }
        setPageImg(cachedImage ? cachedImage : backImg.payload);
        let offers = []
        let incentives = []
        if (
          result.payload.adblock?.suggestions &&
          result.payload.adblock?.suggestions?.offers.length > 0
        ) {
          offers = result.payload.adblock.suggestions.offers.map(
            (item, index) => ({
              ...item,
              offer_type: item.offer_type.toLowerCase() === 'price ea' ? 'Price ea' :  item.offer_type,
              isValid: validateOffer(item),
              index,
            })
          );
          updateOfferSggs4DigitalCoupon = true;  
        } else {
          setOfferData([]);
          initInfo();
          setCurrentItemType("");
        }
        if(result.payload.adblock.group !== null && result.payload.adblock.group.offer_type) {
          let group_offers = result.payload.adblock.group.offer_text.split(",")
          group_offers.forEach(
            group_offer => {
              const newIndex = offers.length > 0 ? offers[offers.length - 1].index + 1 : 0;
              const newOffer = {
                ...OFFER_INIT_INFO,
                index: newIndex,
                isValid: true,
              };
              let text = group_offer;
              let offer_text = extractTextBetweenFirstBalancedParenthesis(text);
              let offer_type = 'Group'; // Temporary for existing Offer Text
              if (offer_text) {
                let index = offer_text.indexOf(" - ")
                offer_type = offer_text.slice(0, index).trim();
                offer_text = offer_text.slice(index + 2).trim();
                let reg_price = text.match(/\(RPS:(.+?)\)/);
                reg_price = Array.isArray(reg_price) ? reg_price[1] : ''
                let reg_price2_d = text.match(/\(RPS2:(.+?)\)/);
                reg_price2_d = Array.isArray(reg_price2_d) ? reg_price2_d[1] : ''
                let limit = text.match(/\(Limit:(.+?)\)/);
                limit = Array.isArray(limit) ? limit[1] : ''
                let min_qty_dollar_f = text.match(/\(MinQty:(.+?)\)/) ? true : false;
                let min_qty = min_qty_dollar_f ? text.match(/\(MinQty:(.+?)\)/) : null;
                min_qty = Array.isArray(min_qty) ? min_qty[1] : ''
                let must_buy_f = text.match(/\(MustBuy\)/) ? true : false;
                let qty_off_total_purchase_f = text.match(/\(OTP\)/) ? true : false;
                let mix_and_match_f = text.match(/\(MixAndMatch\)/) ? true : false;
                let include_in_net_cost_f = text.match(/\(NetCost\)/) ? true : false;
                let liner_f = text.match(/\(Liner\)/) ? true : false;
                newOffer.reg_price = reg_price;
                newOffer.reg_price2_d = reg_price2_d;
                newOffer.limit = limit;
                newOffer.min_qty_dollar_f = min_qty_dollar_f
                newOffer.min_qty = min_qty
                newOffer.must_buy_f = must_buy_f;
                newOffer.qty_off_total_purchase_f = qty_off_total_purchase_f;
                newOffer.mix_and_match_f = mix_and_match_f;
                newOffer.include_in_net_cost_f = include_in_net_cost_f;
                newOffer.liner_f = liner_f;
              } else {
                offer_text = text;
              }
              newOffer.offer_text = offer_text;
              newOffer.offer_type = offer_type;
              offers = [...offers, newOffer];
            }
            )
          }
        if (existingSuggestion.success) {
          if (
            !result.payload?.offers_approved &&
            existingOfferSuggestion &&
            existingOfferSuggestion?.offers) {
            if (existingOfferSuggestion?.offers.length > 0) {
              const newIndex = offers.length > 0 ? offers[offers.length - 1].index + 1 : 0;
              const newOffer = existingOfferSuggestion.offers.map((item, index) => ({
                ...item,
                isValid: validateOffer(item),
                index: newIndex + index,
                existingOffer: true
              }));
              offers = [...offers, ...newOffer];
            }
          }
        }
          
          if(result.payload.adblock.group !== null && result.payload.adblock.group.incentive_type) {
            let group_incentives = getIncentiveInfo(result?.payload?.adblock?.group?.incentive_text);
            group_incentives.forEach(
              incentives => {
                const newIndex = tempIncentives.length > 0 ?
                  tempIncentives[tempIncentives.length - 1].index + 1 : 0;
                const newIncentive = {
                  incentive_type: incentives.incentive_type,
                  incentive_text: incentives.incentive_text,
                  index: newIndex,
                  isValid: true,
                  group: true,
                  incentive_attach_to: 'Adblock',
                  incentive_limit: incentives.limit,
                  incentive_include_net_cost: incentives.net_cost
                };
                tempIncentives = [...tempIncentives, newIncentive];
              })
          }

          if (result.payload.adblock.suggestions?.incentives) {
            let incentive_suggestions = result.payload.adblock.suggestions?.incentives;
            incentive_suggestions.forEach(
              incentives => {
                const newIndex = tempIncentives.length > 0 ?
                  tempIncentives[tempIncentives.length - 1].index + 1 : 0;
                const newIncentive = {
                  incentive_type: incentives.incentive_type,
                  incentive_text: incentives.incentive_text,
                  index: newIndex,
                  isValid: true,
                  incentive_attach_to: 'Adblock',
                  incentive_limit: incentives.limit,
                  incentive_include_net_cost: incentives.net_cost
                };
                tempIncentives = [...tempIncentives, newIncentive];
              }
            )
          }
          
          if(updateOfferSggs4DigitalCoupon && digitalCouponAppliedRef.current)
            updateOffersBasedOnDigitalCoupon(offers, setOfferData)
          else 
            await setOfferData(offers);
            
            if (tempIncentives.length > 0 && incentiveData.length > 0) {
              setIncentiveData(...incentives, tempIncentives);
            } else if (tempIncentives.length > 0 && !incentiveData.length > 0) {
              setIncentiveData(tempIncentives);
            } else {
              setIncentiveData(incentives);
            }
            if ((result.payload.split && result.payload.adblock.products.length !== offers.length)){
              setIsSubmitDisabled(true);
            }
  
            if (offers.length > 0) {
              setCurrentInfo(offers[0]);
              currentOfferIndex.setValue(0);
              setCurrentItemType("offer");
              setFieldNames(liner_disabled ? OFFER_INFO_FIELDS.filter(offer => offer !== 'liner_f') : OFFER_INFO_FIELDS);
              setOfferSuggestionData({
                ...offerSuggestionData,
                offer_text: offers.slice(0, 9).map((item, index) => {
                  return {
                    title: item.offer_text,
                    value: item.offer_text,
                  };
                }),
              });
            }
        setProductData(result.payload.adblock.products);

        // Enable Coupon Meta Check if Coupon Page
        const metaDataFromPayload = {
          ...result.payload.adblock,
          coupon_f: true
        }
        setMetaDataFromPayload(result.payload.page?.page_loc === "4" ? metaDataFromPayload : result.payload.adblock);

        if(result.payload.adblock.group !== null && result.payload.adblock.group.limited_time) {
          const start = result.payload.adblock.group?.start;
          const end = result.payload.adblock.group?.end;
          updateLimitedTimeDates({
            limitedDateFrom: start,
            limitedDateTo: end,
          }, true)
        }
        setAdblockFilename(result.payload.adblock.filename);
          
      } else {
        setOfferData([]);
        setProductData([]);
        setQueueCount(0);
        setSplit(false);
        setIsEndQueue(true);
      }
    }
    postPageEvents("ready", "");
    setIsFetching(false);
  };

  const navigateNext = () => {
    fetchAdBlock();
  };

  const handleLinkCopy = () => {
    const el = document.createElement("input");
    el.value =
      location.origin +
      "/offer/" +
      currentCircularId +
      "/" +
      currentPageId +
      "/" +
      currentAdBlockId;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopiedText(true);
    setTimeout(() => {
      setCopiedText(false);
    }, 2500);
  };

  /*
  ///////////////////////////////////////
  ////// Set Adblock Flags /////////////
  /////////////////////////////////////
  */

  const handleReject = async (reject_message, remove_children) => {
    setIsSubmitting(true);
    const result = await rejectAdblock(currentAdBlockId, "offers", reject_message, remove_children);

    if (result.success) {
      navigateNext();
    }
    setIsSubmitting(false);
  };

  const handleApprove = async () => {
    setIsSubmitting(true);

    const int_keys = [
      //"unit_price",
      "reg_price",
      "reg_price2_d",
      "limit",
      "coupon_amount",
      "rebate_amount",
      "min_qty",
    ];

    let offers = offerData
    // .map((offer) => omit(offer, ["index"]));
    if (productData.length < 1) {
      setIsSubmitDisabled(true);
      setIsSubmitting(false);
      return
    }
    if ((offers.length < 1 && incentiveData.length < 1 && !metaData[1].value)) {
      setIsSubmitDisabled(true);
      setIsSubmitting(false);
      return
    }
    let index = 0;
    offers.forEach((offer) => {
      Object.entries(offer).forEach(([key, value]) => {
        if (value === "" && int_keys.indexOf(key) !== -1) {
          offer[key] = null;
        }
      });

      offer['order_num'] = index;
      index++;

    });

    offers = offers.map(offer => omit(offer, 'index'))

    for (const value of offers) {
      const toValidate = {
        reg_price: value.reg_price,
        reg_price2_d: value.reg_price2_d,
        limit: value.limit,
        coupon_amount: value.coupon_amount,
        rebate_amount: value.rebate_amount,
        min_qty: value.min_qty,
      };

      try {
        await offerValidationSchema.validate(toValidate, { abortEarly: false });
      } catch (err) {
        console.error('Validation failed');
        if (err.name === 'ValidationError') {
          err.inner.forEach((error) => {
            setIsSubmitting(false);
            toast.error(`${error.message}`, {
              position: "bottom-right",
              autoClose: 8000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              progress: undefined,
              theme: "dark"
            });
          });
        }
        return;
      }
    }

    const adblock = {
      ...getMetaData(),
      split: split ? 1 : 0,
      event_nm: events.value.join(),
      incentive_text: incentiveData
        .filter(
          (a) => validateIncentive(a) && a?.incentive_attach_to === "Adblock"
        )
        .map((a) => `${a?.incentive_type ? `(${a?.incentive_type}) `
          : ''}${a.incentive_text ? a.incentive_text
            : ''} (${a?.incentive_limit ? `${a?.incentive_limit}`
              : ""}) (${a?.incentive_include_net_cost ? `Net Cost` : ''})`)
        .join(","),
    }

    const endTime = new Date().toISOString().replace(',','')

    if (isImported) {
      adblock.offer_import_started_at = startTime
      adblock.offer_import_finished_at = endTime
    } else {
      adblock.offer_finished_at = endTime
    }

    let data = {
      adblock: adblock,
      offers: offers,
      message_ID: messageID,
    };

    await saveCircularIncentives(
      currentCircularId,
      incentiveData.filter(
        (a) => validateIncentive(a) && a?.incentive_attach_to === "Circular"
      )
    );
    await savePageIncentives(
      currentPageId,
      incentiveData.filter(
        (a) => validateIncentive(a) && a?.incentive_attach_to === "Page"
      )
    );

    console.log('approve', data);
    const result = await approveOffer(currentAdBlockId, data);
    if (result.success) {
      const res = await getCurrentUserAPI();
      if (res.success) {
        setLiveCounterData(res.results);
      }
      initInfo();
      navigateNext();
    } else {
      //TODO: System error message
    }
    setIsSubmitting(false);
  };

  const handleSkipAdblock = async () => {
    setIsSubmitting(true);
    const result = await skipTopDownAdblock(
      currentAdBlockId,
      {
        message_id: messageID,
      },
      "offers"
    );
    if (result.success) {
      navigateNext();
    } else {
      //TODO: System error message
    }
    setIsSubmitting(false);
  };

  const handleDoNotEnterAdblock = async () => {
    setIsSubmitting(true);
    const result = await doNotEnterTopDownAdblock(
      currentAdBlockId,
      {
        message_id: messageID,
      },
      "offers"
    );
    if (result.success) {
      const res = await getCurrentUserAPI();
      if (res.success) {
        setLiveCounterData(res.results);
      }
      navigateNext();
    } else {
      //TODO: System error message
    }
    setIsSubmitting(false);
  };

  const refetchAdblocks = async () => {
    const result = await getPageAdblocks(currentPageId);
    const newData = result?.payload?.adblocks;
    setAdblockData(newData);
  };

  const handleAddItem = (type) => {
    switch (type) {
      case "offer":
        handleAddOffer();
        break;
      case "incentive":
        handleAddIncentive();
        break;

      default:
        break;
    }
    setCurrentItemType(type);
  };

  const handleSplit = (value) => {
    console.log('split', value);
    setSplit(value);
  }

  /*
  ///////////////////////////////////////
  ////// Offer Controls /////////////
  /////////////////////////////////////
  */

  const handleAddOffer = () => {
    const newIndex =
      offerData.length > 0 ? offerData[offerData.length - 1].index + 1 : 0;
    const newOffer = {
      ...OFFER_INIT_INFO,
      index: newIndex,
      isValid: false,
    };
    //We need to always have net cost and total purchase checked
    newOffer.include_in_net_cost_f = true;
    newOffer.qty_off_total_purchase_f = true;
    setOfferData([...offerData, newOffer]);
    currentOfferIndex.setValue(newIndex);
    setCurrentInfo(newOffer);
    setFieldNames(isLinerDisabled ? OFFER_INFO_FIELDS.filter(offer => offer !== 'liner_f') : OFFER_INFO_FIELDS);
    setCurrentItemType("offer");
  };

  const handleRemoveOffer = (event, index) => {
    event.stopPropagation();
    const idx = offerData.findIndex((offer) => offer.index === index);

    const newOffersData = offerData
      .filter((item) => item?.index !== index)
      .map((item, index) => ({
        ...item,
        isValid: validateOffer(item),
        index,
      }));
    setOfferData(newOffersData);

    if (currentItemType === "offer") {
      if (newOffersData.length === 0) {
        if (incentiveData.legnth > 0) {
          setCurrentItemType("incentive");
          setCurrentInfo(incentiveData[0]);
          currentIncentiveIndex.setValue(incentiveData[0]);
        } else {
          setCurrentInfo({});
          currentOfferIndex.setValue(0);
          setCurrentItemType("");
        }
      } else {
        const newIdx = newOffersData.length > idx ? idx : idx - 1;
        currentOfferIndex.setValue(newOffersData[newIdx].index);
        setCurrentInfo(newOffersData[newIdx]);
      }
    }
  };

  const handleCloneOffer = (event, index) => {
    event.stopPropagation();
    if (offerData.length > 0) {
      const newIndex = offerData[offerData.length - 1].index + 1;
      setOfferData([
        ...offerData,
        { ...currentInfo, index: newIndex, uuid: undefined },
      ]);
      currentOfferIndex.setValue(newIndex);
    }
  };

  const handleOfferSelect = (index) => {
    currentOfferIndex.setValue(index);
    setCurrentItemType("offer");
    setFieldNames(isLinerDisabled ? OFFER_INFO_FIELDS.filter(offer => offer !== 'liner_f') : OFFER_INFO_FIELDS);
    setActiveField(OFFER_INFO_FIELDS[0]);
    setCurrentInfo(offerData[index]);
  };

  ///////////////////////////////////////
  ////// Incentive Controls /////////////
  /////////////////////////////////////

  const handleAddIncentive = () => {
    const newIndex =
      incentiveData.length > 0
        ? incentiveData[incentiveData.length - 1].index + 1
        : 0;
    const newIncentive = {
      ...INCENTIVE_INIT_INFO,
      index: newIndex,
      isValid: false,
    };
    setIncentiveData([...incentiveData, newIncentive]);
    currentIncentiveIndex.setValue(newIndex);
    setCurrentItemType("incentive");
    setFieldNames(INCENTIVE_INFO_FIELDS);
    setCurrentInfo(newIncentive);
  };

  const handleRemoveIncentive = (index) => {
    // event.stopPropagation();
    const idx = incentiveData.findIndex(
      (incentive) => incentive.index === index
    );
    
    const newIncentivesData = incentiveData
      .filter((item) => item?.index !== index)
      .map((item, index) => ({
        ...item,
        isValid: validateOffer(item),
        index,
      }));

    if (currentItemType === "incentive") {
      if (newIncentivesData.length === 0) {
        if (offerData.length > 0) {
          setCurrentItemType("offer");
          setCurrentInfo(offerData[0]);
          currentOfferIndex.setValue(offerData[0].index);
        } else {
          setCurrentInfo({});
          currentIncentiveIndex.setValue(0);
          setCurrentItemType("");
        }
      } else {
        const newIdx = newIncentivesData.length > idx ? idx : idx - 1;
        currentIncentiveIndex.setValue(newIncentivesData[newIdx].index);
        setCurrentInfo(newIncentivesData[newIdx]);
      }
    }
    setIncentiveData(newIncentivesData);
  };

  const handleCloneIncentive = (event, index) => {
    event.stopPropagation();
    if (incentiveData.length > 0) {
      const newIndex = incentiveData[incentiveData.length - 1].index + 1;
      setIncentiveData([
        ...incentiveData,
        { ...currentInfo, index: newIndex, uuid: undefined },
      ]);
      currentIncentiveIndex.setValue(newIndex);
      setCurrentItemType("incentive");
      setFieldNames(INCENTIVE_INFO_FIELDS);
    }
  };

  const handleIncentiveSelect = (index) => {
    currentIncentiveIndex.setValue(index);
    setCurrentItemType("incentive");
    setFieldNames(INCENTIVE_INFO_FIELDS);
    setActiveField(INCENTIVE_INFO_FIELDS[0]);
    setCurrentInfo(incentiveData[index]);
  };

  useEffect(() => {
    if (offerData.length === 0 && incentiveData.length === 0) {
      setCurrentItemType("");
      setActiveField("");
    }
    if (split && offerData.length !== productData.length){
      setIsSubmitDisabled(true);
    } 
    
    return () => {};
  }, [split, offerData, incentiveData]);
  /*
  ///////////////////////////////////////
  ////// Adblock Refresh ///////////////
  /////////////////////////////////////
  */

  const refreshAdblockSuggestions = async () => {
    postPageEvents("click", "Refresh Quick Selection");
    setSuggestionsLoading(true);
    const result = await updateAdblockSuggestions(currentAdBlockId);
    if (result.success) {
      let offers = [];
      setIsApproved(result.payload.offers_approved);

      //This means the adblock is approved
      if (result.payload.offers.length > 0) {
        offers = result.payload.offers.map((item, index) => ({
          ...item,
          isValid: validateOffer(item),
          index,
        }));
      } else if (result.payload.suggestions.offers.length > 0) {
        offers = result.payload.suggestions.offers.map((item, index) => ({
          ...item,
          isValid: validateOffer(item),
          index,
        }));
      }
      setOfferData(offers);
      if (offers.length > 0) {
        setCurrentInfo(offers[0]);
        currentOfferIndex.setValue(0);
        setCurrentItemType("offer");
        setFieldNames(isLinerDisabled ? OFFER_INFO_FIELDS.filter(offer => offer !== 'liner_f') : OFFER_INFO_FIELDS);
      }
    }
    setSuggestionsLoading(false);
  };
  
  const handleImport = (data) => {
    setIsImported(true);
    const offers = data.payload
    console.log(offers)
    const offersData = offers.map((item, index) => {
      delete item.uuid;
      return {
      ...item,
      offer_type: item.offer_type.toLowerCase() === 'price ea' ? 'Price ea' :  item.offer_type,
      isValid: validateOffer(item),
      index,
    }});
    setOfferData(offersData);
  } 

  /*
  ///////////////////////////////////////
  ////// Form and Quickslection Changes ///////////////
  /////////////////////////////////////
  */

  const handleSuggestionClick = (value, event) => {
    if (value === "Manual") {
      setIsManualEntry(true);
    } else {
      if (activeField === "offer_type") {
        if (((value === 'Save'|| value === 'save') && useDefaultSaveOfferText) || value !== 'Save') {
          setTimeout(() => {
            updateInfos({
              offer_text: INIT_OFFER_TEXT[value.toLowerCase()],
              offer_type: value,
            });
          }, 300);
        } else {
          updateInfos({
            offer_type: value,
          });
        }
      } else {
        updateActiveInfo(value);
      }
      moveNextField();
    }
  };

  /*
  ///////////////////////////////////////
  ////// Key Commands //////////////////
  /////////////////////////////////////
  */

  // Handle key press for quick selection
  useKeypress(["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"], (event) => {
    if (parseInt(event.key) <= currentSuggestions.length && !isManualEntry) {
      event.preventDefault();
      var value, title;

      //Any time the user clicks zero they are going to manual entry
      if (parseInt(event.key) === 0) {
        value = "Manual";
      } else {
        value = currentSuggestions[parseInt(event.key) - 1].value;
        title = currentSuggestions[parseInt(event.key) - 1].title;
      }

      handleSuggestionClick(value, title);
    }
  });

  //Disable manual entry by pressing escape
  useKeypress(["Escape"], (event) => {
    if (isManualEntry) {
      //setInputFocused(false);
      setIsManualEntry(false);
    } else {
      //setInputFocused(false);
    }

    if (document.activeElement) {
      document.activeElement.blur();
    }
  });

  //Remove Offers
  useKeypress(["-"], (event) => {
    // if (event.altKey) {
    //   handleRemoveOffer();
    // }
  });

  //Add Offers
  useKeypress(["+"], (event) => {
    if (event.altKey) {
      handleAddOffer();
    }
  });

  //Clone Offers
  useKeypress(["="], (event) => {
    // if (event.altKey) {
    //   handleCloneOffer();
    // }
  });

  //Cycle through offers
  useKeypress(["["], (event) => {
    event.preventDefault();
    if (event.shitfKey) {
      //If we go backwards to the start, go back to top.
      if (currentOfferIndex.value === 0) {
        currentOfferIndex.setValue(offerData.length - 1);
      } else {
        currentOfferIndex.setValue(currentOfferIndex.value - 1);
      }
    } else {
      //If we go forward to the end, go back to start.
      if (currentOfferIndex.value === offerData.length - 1) {
        currentOfferIndex.setValue(0);
      } else {
        currentOfferIndex.setValue(currentOfferIndex.value + 1);
      }
    }
  });

  //Approve offers
  useKeypress(["q", "Q"], (event) => {
    if (event.altKey) {
      handleApprove();
    }
  });

  //Approve offers
  useKeypress(["w", "W"], (event) => {
    if (event.altKey) {
      handleDoNotEnterAdblock();
    }
  });

  //Approve offers--
  useKeypress(["e", "E"], (event) => {
    if (event.altKey) {
      handleSkipAdblock();
    }
  });

  //Approve offers
  useKeypress(["r", "R"], (event) => {
    if (event.altKey) {
      handleReject();
    }
  });

  //refresh Suggestions
  useKeypress(["t", "T"], (event) => {
    if (event.altKey) {
      refreshAdblockSuggestions();
    }
  });

  //Move to next field item, only if not in manual entry
  useKeypress(["Tab"], (event) => {
    event.preventDefault();
    if (event.shitfKey) {
      movePreviousField();
    } else {
      moveNextField();
    }
  });

  return (
    <SkeletonTheme color="#202020" highlightColor="#444">
      <MainLayout>
        <Header>
          {isLoading ? (
            <div className="d-flex">
              <div className="my-auto">
                <Skeleton height={24} width={800} />
              </div>
            </div>
          ) : (
            <div className="d-flex row w-100">
              <div className="my-auto d-flex">
                {/* <BreadCrumb className="ml-1 my-auto">Circulars</BreadCrumb> */}
                <BreadCrumb className="ml-3 my-auto">{circularName}</BreadCrumb>
                <BreadCrumb className="ml-3 my-auto">
                  {currentPageId}
                </BreadCrumb>
                <PageType className="ml-3 my-auto">
                    {pageType}
                  </PageType>
                <HistoryDropdown className="ml-4" width={320} type="offer" />
                {currentAdBlockId ? (
                  <Button
                    buttonTheme="dark"
                    size="small"
                    width="120px"
                    className="ml-2 my-auto"
                    onClick={handleLinkCopy}
                  >
                    {!copiedText ? "COPY LINK" : "COPIED!"}
                  </Button>
                ) : null}
              </div>

              <PageInfo className="ml-auto my-auto ">
                {` Adblocks remaining: ${queueCount} `}
              </PageInfo>
              {(!isLoading && adblockData.similar_adblock && !adblockData.has_children) &&
                <Button
                  // buttonTheme={"dark"}
                  width="50px"
                  size="small"
                  className="ml-2 my-auto"
                  onClick={() => imageMatchingModal.openModal()}
                  isLoading={isSubmitting}
                  disabled={isFetching}
                  eventType="flag"
                >
                  <BsImageAlt />
                </Button>
              }
              <FlagButtonGroup
                isSubmitting={isSubmitting}
                isFetching={isSubmitting}
                onDoNotEnter={handleDoNotEnterAdblock}
                onSkip={handleSkipAdblock}
                onReject={handleReject}
                onApprove={handleApprove}
                isDoNotEnter={isDoNotEnter}
                isSkipped={isSkipped}
                isRejected={isRejected}
                isApproved={isApproved}
                isAllOfferValid={allOffersValid}
                isSubmitDisabled={isSubmitDisabled || isOfferAndIncentiveEmpty}
              />
            </div>
          )}
        </Header>

        <Content>
          <div className="d-flex flex-column" style={{ width: "320px" }}>
            <AdblockLocation
              backImg={pageImg && `data:image/jpeg;base64,${pageImg}`}
              pageWidth={pageWidth}
              pageHeight={pageHeight}
              adblockCoords={adblockCoords}
            />
            <MetadataSection title="ADBLOCK DATA">
              <EventsDropDown
                pageId={currentPageId}
                {...events}
                onRefresh={refetchAdblocks}
                onChange={(e) => events.setValue(e)}
              />
              <MetaData className="mt-3" />
            </MetadataSection>
          </div>
          <AdblockImage
            type="offers"
            filename={adblockFilename}
            imageHeight={imageHeight}
            imageWidth={imageWidth}
            blockRef={blockRef}
            isFetching={isFetching}
            queueCount={queueCount}
            currentAdBlockData={adblockData}
            isEndQueue={isEndQueue}
            circularId={currentCircularId}
            pageId={currentPageId}
            sizeData={sizeData}
          />

          <div className="d-flex flex-column flex-1">
            <OfferSelector
              title={`OFFERS & INCENTIVES ${
                currentOfferIndex.value +
                1 +
                (currentItemType === "incentive"
                  ? currentIncentiveIndex.value + 1
                  : 0)
              }/${offerData.length + incentiveData.length}`}
              max={offerData.length && productData.length}
              onChange={handleSplit}
              productData={productData}
              offerData={offerData}
              value={split}
              onAdd={handleAddItem}
              disableSubmit={(value) => setIsSubmitDisabled(value)}
              isAddable
            >
              {offerData.length === 0 && incentiveData.length === 0 ? (
                <EmptyOfferSelector
                  onCreateOffer={handleAddOffer}
                  onCreateIncentive={handleAddIncentive}
                />
              ) : (
                <div className="d-flex">
                  <ItemsList>
                    {offerData.map((offer, index) => (
                      <OfferItem
                        key={`offer${index}`}
                        className="d-flex flex-row"
                        active={
                          index === currentOfferIndex.value &&
                          currentItemType === "offer"
                        }
                        onClick={() => handleOfferSelect(index)}
                        onRemove={handleRemoveOffer}
                        onClone={handleCloneOffer}
                        data={offer}
                        index={index}
                      />
                    ))}
                    {incentiveData.map((incentive, index) => (
                      <IncentiveItem
                        key={`incentive${index}`}
                        className="d-flex flex-row"
                        active={
                          index === currentIncentiveIndex.value &&
                          currentItemType === "incentive"
                        }
                        onClick={() => handleIncentiveSelect(index)}
                        onRemove={() => handleRemoveIncentive(index)}
                        onClone={() => handleCloneIncentive(index)}
                        data={incentive}
                        index={index}
                        isApproved={isApproved}
                      />
                    ))}
                  </ItemsList>
                </div>
              )}
            </OfferSelector>
            <div
              className="d-flex flex-1"
              style={{ height: "calc(100% - 195px)" }}
            >
              <QuickSelection
                onRefreshSuggestion={() => refreshAdblockSuggestions()}
                isRefresh={adblockData.length > 0}
              >
                {isManualEntry && !isApproved && (
                  <ManualEntry onExit={() => setIsManualEntry(false)} />
                )}
                {((activeField === "offer_text" && isManualEntry) ||
                  (activeField === "offer_text" &&
                    currentSuggestions &&
                    currentSuggestions.length === 0)) && (
                  <OfferTextBuilder
                    type={currentInfo?.offer_type}
                    value={currentInfo?.offer_text}
                    existingOfferTextForSave={firstDefaultSaveOfferText}
                    onChange={(e) => {
                      updateInfos({
                        offer_text: e,
                        qty_off_total_purchase_f: e.match(' each') ? false : true
                      });
                    }}
                    onStartChange={(e) => {
                      if (e) {
                        if (pageType === "Coupon Page" || digitalCouponAppliedRef.current) {
                          const tempMetaData = metaData.map(a => { return { ...a } })
                          tempMetaData.find(a => a.key === "coupon_f").value = true;
                          tempMetaData.find(a => a.key === "coupon_f").coupon_amnt = e;
                          setMetaData(tempMetaData)
                        }
                      }
                    }}
                  />
                )}
                {((activeField === "incentive_text" && isManualEntry) ||
                  (currentSuggestions &&
                    currentSuggestions.length === 0 &&
                    activeField === "incentive_text")) && (
                  <IncentiveTextBuilder
                    type={currentInfo?.incentive_type}
                    value={currentInfo?.incentive_text}
                    onChange={(e) => {
                      updateInfo("incentive_text", e);
                    }}
                  />
                )}
                {
                  //TODO: this part should have its own loading state
                  isFetching || suggestionsLoading ? (
                    <div>Loading...</div>
                  ) : offerData.length === 0 && incentiveData.length === 0 ? (
                    <div className="mt-4 pt-4 text-center">
                      No offer/incentive available
                    </div>
                  ) : (
                    <>
                      <div className="text-blue-light mb-3">
                        {activeOfferInfoText}
                      </div>

                      {isApproved ? (
                        <AdblockApproved />
                      ) : currentSuggestions.length > 0 ? (
                        <>
                          {!isManualEntry && (
                            <QuickSelectionChoice
                              index={0}
                              data={{
                                title: "Manual Entry",
                                value: "Manual",
                              }}
                              active={false}
                              showIndex={!isManualEntry}
                              onClick={handleSuggestionClick}
                            />
                          )}

                          {currentSuggestions
                            .slice(
                              0,
                              isManualEntry ? currentSuggestions.length : 9
                            )
                            .filter((item) => item.title)
                            .map((item, index) => (
                              <QuickSelectionChoice
                                key={`${index}key`}
                                showIndex={!isManualEntry}
                                index={index + (isManualEntry ? 0 : 1)}
                                data={item}
                                active={activeFieldValue === item.value}
                                onClick={handleSuggestionClick}
                              />
                            ))}
                        </>
                      ) : (
                        circularType === 'Training Circular' ?
                          <div
                              className="mt-4 text-center"
                              style={{
                                paddingTop: '180px',
                                color: '#aaa',
                                fontSize: '14px',
                              }}
                            >
                              <h3>Training Circular</h3>
                              <div className="mt-3">
                                Suggestions has been disabled for this circular.
                              </div>
                            </div>
                          :
                          <>
                            <NoSuggestions />
                          </>
                          
                      )}
                    </>
                  )
                }
              </QuickSelection>

              {currentItemType === "offer" ? (
                <OfferInfo 
                  key={'offerinfo-'+currentOfferIndex.value} 
                  offerTypeData={offerTypeData} 
                  useDefaultSaveOfferText={useDefaultSaveOfferText} 
                  isLinerDisabled={isLinerDisabled}
                />
              ) : currentItemType === "incentive" ? (
                <IncentiveInfo />
              ) : (
                <EmptyInfo />
              )}
            </div>
          </div>
        </Content>
        <ImageMatchingModal
          {...imageMatchingModal}
          currentAdBlockData={adblockData}  
          circularName={circularName}
          dupe_block={adblockData.similar_adblock_uuid}
          navigateNext={navigateNext}
          startTime={startTime}
          onImport={(data) => handleImport(data)}
          onError={(err) => toast.error(err)}
        />
      </MainLayout>
      <ToastContainer />
    </SkeletonTheme>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
`;

const MetadataSection = styled(Card)`
  min-width: 240px;
  flex: 1;
  overflow:auto;
`;

const InfoSection = styled(Card)`
  height: calc(100% - 4px);
  flex: 1;
  color: white;
  width: 380px;
  .offer-label {
    color: white;
    font-size: 24px;
  }
`;

const SelectionContainer = styled.div`
  width: 380px;
  flex: 1;
  margin: 2px 2px;
  background: ${(props) => props.theme.palette.backgrounds};
  padding: 12px 8px;
`;

const SelectionHeader = styled.div`
  text-transform: uppercase;
  color: white;
  font-size: ${(props) => props.theme.font.size.m};
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary};
  position: relative;

  svg {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
`;

const SelectionContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
  color: white;
  flex: 1;
  max-height: calc(100% - 12px);
`;

const Header = styled.div`
  height: 48px;
  color: white;
  font-size: 20px;
  display: flex;
  padding: 0 24px;
  background: ${(props) => props.theme.palette.backgrounds};
  margin-bottom: 1px;
`;

const PageInfo = styled.div`
  font-size: 16px;
  color: white;
`;

const PageType = styled.div`
  padding: 5px;
  font-size: 14px;
`;

const ItemsList = styled.div`
  width: 100%;
  overflow: auto;
  max-height: 200px;
  ::-webkit-scrollbar {
    width: 14px;
    background: ${(props) => props.theme.palette.backgrounds};
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${(props) => props.theme.palette.backgrounds};
    border: 1px solid ${(props) => props.theme.palette.itemsHover};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.palette.itemsHover};
    cursor: pointer;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }
`;

const NoItems = ({ onCreate = () => {} }) => {
  return (
    <NoItemsContainer className="mt-2">
      <div>There is no offer available.</div>
      <div>
        {" "}
        <span className="cursor-pointer" onClick={onCreate}>
          <u>Create</u>
        </span>{" "}
        a new offer or flag the adblock appropriately.
      </div>
    </NoItemsContainer>
  );
};

const NoItemsContainer = styled.div`
  font-size: 14px;
`;

const BreadCrumb = styled.div`
  color: white;
  font-size: 14px;
`;
