/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Input2, DropDown2, ToggleButton, AutoComplete } from "components";
import { useInput } from "hooks";
import { getProductBrandAutocompleteApi } from "api";
import AutocompleteFreeText from "components/AutocompleteFreeText";
import { FREE_ITEM_INCENTIVE_PREDICTIVE_TEXT } from "config";
export const IncentiveFreeItemTextBuilder = ({
  className,
  value = "",
  onChange = () => {},
}) => {
  const isUpTo = useInput(false);
  const start = useInput(2);
  const end = useInput("");
  const type = useInput();
  const freeText = useInput("")
  const val = useInput("");
  const isPurchase = useInput(false);
  const pVal = useInput("");
  const pType = useInput("");
  const suffix = useInput("");

  const typeData = [
    { id: "$", title: "$" },
    { id: "Unit", title: "Unit" },
  ];

  const [incentiveText, setIncentiveText] = useState(value)

  const formatFirstLine = (st, ed, tp) => {
    let result = "";

    if (tp === "$") {
      if (st && ed) {
        result += `${st}-$${ed}`;
      } else if (st) {
        result += st;
      } else if (ed) {
        result += ed;
      }
      result = "$" + result;
    } else {
      if (st && ed) {
        result += `${st}-${ed}`;
      } else if (st) {
        result += st;
      } else if (ed) {
        result += ed;
      }
      // result = result + " Unit";
    }
    return result ? ` ${result}` : "";
  };
  const formatPurchase = (isP, pV, pT) => {
    if (isP) {
      if (pT === "$") {
        return ` w/purchase/$${pV}`;
      } else {
        return ` w/purchase/${pV}`;
      }
    }
    return "";
  };
  const handleUpdateValue = (isUT, st, ed, tp, va, isP, pV, pT, suf, ft) => {
    setIncentiveText(
      `${isUT ? "Up To " : ""}Free${formatFirstLine(st, ed, tp)}${ft ? ` ${ft}` : ""}${
        va ? ` ${va}` : ""
      }${formatPurchase(isP, pV, pT)}${suf ? ` ${suf}` : ""}`
    );
  };

  useEffect(() => {
    let main = value.trim();
    if (main.startsWith("Up To")) {
      isUpTo.setValue(true);
      main = main.slice(5).trim();
    } else {
      isUpTo.setValue(false);
    }
    if (main.startsWith("Free")) {
      main = main.slice(4).trim();
      const splits = main.split(" ");
      if (splits.length > 0) {
        if (splits[0].startsWith("$")) {
          type.setValue("$");
          const v = splits[0].slice(1).trim();
          const dashIdx = v.indexOf("-");
          if (dashIdx > 0) {
            start.setValue(v.slice(0, dashIdx).trim());
            end.setValue(v.slice(dashIdx + 2).trim());
          } else {
            start.setValue(v);
          }
          main = splits.slice(1).join(" ");
        } else if (splits.length > 1 && splits[1] === "Unit") {
          type.setValue("Unit");
          const v = splits[0].trim();
          const dashIdx = v.indexOf("-");
          if (dashIdx > 0) {
            start.setValue(v.slice(0, dashIdx).trim());
            end.setValue(v.slice(dashIdx + 2).trim());
          } else {
            start.setValue(v);
          }
          main = splits.slice(2).join(" ");
        }
      }
      const pIdx = main.indexOf("w/purchase/");
      if (pIdx >= 0) {
        // val.setValue(main.slice(0, pIdx).trim());
        main = main.slice(pIdx + 11).trim();
        const sufIdx = main.indexOf(" ");

        if (sufIdx > 0) {
          const v = main.slice(0, sufIdx).trim();
          if (v.startsWith("$")) {
            pType.setValue("$");
            pVal.setValue(v.slice(1).trim());
          } else {
            pType.setValue("Unit");
            if (typeof v === Number){
              pVal.setValue(v.trim());
            }
          }
          suffix.setValue(main.slice(sufIdx).trim());
        } else {
          const v = main.trim();
          if (v.startsWith("$")) {
            pType.setValue("$");
            pVal.setValue(v.slice(1).trim());
          } else {
            pType.setValue("Unit");
            if (typeof v === Number){
              pVal.setValue(v.trim());
            }
          }
          suffix.setValue("");
        }
      } else {
        // val.setValue(main);
        isPurchase.setValue(false);
        // suffix.setValue("");
      }
    }

    return () => {};
  }, [value]);

  useEffect(() => {
    onChange(incentiveText);
  }, [incentiveText])

  return (
    <div className={`${className} mt-1 py-2`}>
      <Title>Incentive Text Builder</Title>
      <Container className="mt-2">
        <ToggleButton
          className="mt-auto mb-2"
          label="Up To"
          value={isUpTo.value}
          onChange={(e) => {
            isUpTo.setValue(e);
            handleUpdateValue(
              e,
              start.value,
              end.value,
              type.value,
              val.value,
              isPurchase.value,
              pVal.value,
              pType.value,
              suffix.value,
              freeText.value
            );
          }}
        />
        <div className="mt-auto mb-2 ml-2">Free</div>
        <div style={{ width: 70, marginLeft: 10 }}>
          <Input2
            label="Start"
            value={start.value}
            onChange={(e) => {
              start.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                e,
                end.value,
                type.value,
                val.value,
                isPurchase.value,
                pVal.value,
                pType.value,
                suffix.value,
                freeText.value
              );
            }}
          />
        </div>
        <div style={{ width: 60, marginLeft: 10 }}>
          <Input2
            label="End"
            value={end.value}
            onChange={(e) => {
              end.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                start.value,
                e,
                type.value,
                val.value,
                isPurchase.value,
                pVal.value,
                pType.value,
                suffix.value,
                freeText.value
              );
            }}
          />
        </div>
        <div style={{ width: 60, marginLeft: 10 }}>
          <DropDown2
            label="Type"
            data={typeData}
            width={70}
            value={type.value}
            onChange={(e) => {
              type.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                start.value,
                end.value,
                e,
                val.value,
                isPurchase.value,
                pVal.value,
                pType.value,
                suffix.value,
                freeText.value
              );
            }}
          />
        </div>
      </Container>
      <Container className="mt-1">
        <div style={{ width: 240 }}>
          <AutocompleteFreeText
            label="Free Text"
            value={freeText.value}
            data={FREE_ITEM_INCENTIVE_PREDICTIVE_TEXT}
            onChange={(e) => {
              freeText.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                start.value,
                end.value,
                type.value,
                val.value,
                isPurchase.value,
                pVal.value,
                pType.value,
                suffix.value,
                e
              )
            }}
          />
        </div>
      </Container>
      <Container className="mt-1">
        <div style={{ width: 240 }}>
        <AutoComplete
            label="Product/Service"
            idField="ext_id"
            inputId="ext_brand_i"
            func={getProductBrandAutocompleteApi}
            onSelectValue={(e) => {
              val.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                start.value,
                end.value,
                type.value,
                e,
                isPurchase.value,
                pVal.value,
                pType.value,
                suffix.value,
                freeText.value
              )
            }}
            value={val.value}
            onChange={(e) => {
              val.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                start.value,
                end.value,
                type.value,
                e,
                isPurchase.value,
                pVal.value,
                pType.value,
                suffix.value,
                freeText.value
              )
            }}
            showAddProduct={false}
          />
        </div>
      </Container>
      <Container className="mt-1">
        <ToggleButton
          className="mt-auto mb-2"
          label="w/purchase"
          value={isPurchase.value}
          onChange={(e) => {
            isPurchase.setValue(e);
            handleUpdateValue(
              isUpTo.value,
              start.value,
              end.value,
              type.value,
              val.value,
              e,
              pVal.value,
              pType.value,
              suffix.value,
              freeText.value
            );
          }}
        />

        <div style={{ width: 60, marginLeft: 10 }}>
          <Input2
            label="Value"
            value={pVal.value}
            disabled={!isPurchase.value}
            onChange={(e) => {
              pVal.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                start.value,
                end.value,
                type.value,
                val.value,
                isPurchase.value,
                e,
                pType.value,
                suffix.value,
                freeText.value
              );
            }}
          />
        </div>
        <div style={{ width: 70, marginLeft: 10 }}>
          <DropDown2
            label="Type"
            data={typeData}
            width={70}
            disabled={!isPurchase.value}
            value={pType.value}
            onChange={(e) => {
              pType.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                start.value,
                end.value,
                type.value,
                val.value,
                isPurchase.value,
                pVal.value,
                e,
                suffix.value,
                freeText.value
              );
            }}
          />
        </div>
        <div style={{ width: 90, marginLeft: 10 }}>
          <Input2
            label="Suffix"
            value={suffix.value}
            onChange={(e) => {
              suffix.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                start.value,
                end.value,
                type.value,
                val.value,
                isPurchase.value,
                pVal.value,
                pType.value,
                e,
                freeText.value
              );
            }}
          />
        </div>
      </Container>
    </div>
  );
};

const Title = styled.div`
  color: #66c0e1;
  font-size: 16px;
`;
const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  padding: 8px;
  display: flex;
`;
