/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Modal, Button } from "components";
import styled from "styled-components";

export const MethodologyReminder = ({
  open,
  onClose,
  message = [],
  onApprove = () => { }
}) => {

  const handleCancel = () => {
    onClose();
  }

  const handleApprove = () => {
    onApprove();
    onClose();
  }

  const renderTextWithLinks = (text) => {
    // Regex to identify URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    // Split text by URLs
    const parts = text.split(urlRegex);
  
    return parts.map((part, index) => {
      if (urlRegex.test(part)) {
        // If part matches URL, render as a link
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      } else {
        // Otherwise, render as plain text
        return part;
      }
    });
  };

  return (
    <Modal open={open} onClose={onClose} size="medium" title={"Approve Page"}>
      <MessageStyle>
        <div>
          <div>Are you sure you want to approve this block?</div>
          {console.log(message)}
          {message.length > 0 ?
            message.map((item) => <WarningMessage>
              <WarningText>
                {renderTextWithLinks(item?.replace(/"/g, ''))}
              </WarningText>
            </WarningMessage>
            ) : null}
        </div>
      </MessageStyle>
      <div className="d-flex mt-4">
        <Button
          size="small"
          buttonTheme="dark"
          width="fit-content"
          className="ml-auto"
          type="button"
          onClick={() => handleCancel()}
        >
          NO
        </Button>
        <Button
          size="small"
          buttonTheme="primary"
          width="fit-content"
          className="ml-4"
          type="button"
          onClick={() => handleApprove()}
        >
          YES
        </Button>
      </div>
    </Modal>
  );
};

const MessageStyle = styled.div`
    font-size:14px;
`;
const WarningMessage = styled.div`
    font-size:14px;
    padding:10px;
`;

const WarningText = styled.span`
  color:${(props) => props.theme.palette.warning};
`;
