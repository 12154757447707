import { openDB } from 'idb';

const dbPromise = openDB('image-cache-db', 1, {
  upgrade(db) {
    db.createObjectStore('images');
  },
});

export const setCachedImage = async (id, base64Image) => {
  const db = await dbPromise;
  await db.put('images', base64Image, id);
};

export const getCachedImage = async (id) => {
  const db = await dbPromise;
  return await db.get('images', id);
};