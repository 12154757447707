import React from "react";
import styled from "styled-components";
import { TableHeaderColumn } from "components";
import { Button } from "components";
import {
  ReportsModal,
} from "modals";
import { useModal } from "hooks";
import { useAuthContext } from "contexts";

export const TableHeader = ({ sortCol, sortType, onSort = () => {} }) => {

  const reportsModal = useModal();
  const {userInfo} = useAuthContext();
  return (
    <Container>
      <TableHeaderColumn></TableHeaderColumn>
      <TableHeaderColumn label="Name"></TableHeaderColumn>
      <TableHeaderColumn label="Progress"></TableHeaderColumn>
      <TableHeaderColumn label="Stage"></TableHeaderColumn>
      <TableHeaderColumn label="Pages"></TableHeaderColumn>
      <TableHeaderColumn label="Adblocks"></TableHeaderColumn>
      <TableHeaderColumn label="Tier"></TableHeaderColumn>
      {userInfo?.user.is_superuser ?
        <Button
          width="80px"
          size="small"
          buttonTheme="dark"
          onClick={() => reportsModal.openModal()}
        >Reports</Button>
        :
        <TableHeaderColumn></TableHeaderColumn>
      }
      <ReportsModal
          {...reportsModal}
        />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  padding: 0 0px 0 32px;
  color: white;
  font-size: ${(props) => props.theme.palette.m};
  margin: 28px 12px 15px 12px;
  & > div:nth-child(1) {
    width: 0px;
  }
  & > div:nth-child(2) {
    flex: 1;
  }
  & > div:nth-child(3) {
    width: 550px;
  }
  & > div:nth-child(4) {
    width: 200px;
  }
  & > div:nth-child(5) {
    width: 150px;
  }
  & > div:nth-child(6) {
    width: 170px;
  }
  & > div:nth-child(7) {
    width: 100px;
  }
  & > div:nth-child(8) {
    width: 80px;
  }
`;
