import React from "react";
import styled from "styled-components";
import { useMainContext } from "contexts";
import {
  FaCheckCircle,
  FaClone,
  FaTrash,
  FaMinusCircle,
  FaTags,
  FaInfoCircle
} from "react-icons/fa";

export const OfferItem = ({
  active,
  onClick = () => {},
  data,
  onClone = () => {},
  onRemove = () => {},
  index = 0,
  displayOnly = false,
}) => {
  const { postPageEvents } = useMainContext();
  return (
    <Items
      className="d-flex flex-row"
      active={active}
      onClick={() => {
        postPageEvents("click", `Offer`);
        onClick();
      }}
    >
      {!displayOnly &&
        <>
          <ProductSelectedBar active={active}></ProductSelectedBar>
          <ProductValidator valid={data?.isValid}>
            {data?.isValid ? <FaCheckCircle /> : <FaMinusCircle />}
          </ProductValidator>
          <Type>
            <FaTags />
          </Type>
          {data?.existingOffer && <Type>
            <FaInfoCircle />
          </Type>
          }
        </>
      }
      {data?.offer_type ? (
        <div className="flex-1">
          
          {data?.offer_type} - {data?.offer_text}
        </div>
      ) : data?.incentive_type ? (
        <div className="flex-1">
          {data?.incentive_type} - {data?.incentive_text}
        </div>) : (
        <div className="flex-1">{`Undeclared Offer ${index + 1}`}</div>
      )}
      {!displayOnly &&
        <>
          <ButtonIcon
            active
            onClick={(event) => {
              onClone(event, data?.index);
              postPageEvents("click", `Offer clone`);
            }}
          >
            <FaClone />
          </ButtonIcon>

          <ButtonIcon
            active
            onClick={(event) => {
              event.stopPropagation();
              onRemove(event, data?.index);
              postPageEvents("click", `Offer remove`);
            }}
          >
            <FaTrash />
          </ButtonIcon>
        </>}
    </Items>
  );
};

const Items = styled.div`
  border: 1px solid
    ${(props) =>
      props.active
        ? props.theme.palette.primaryOutline
        : props.theme.palette.backgrounds};
  width: 100%;
  cursor: pointer;
  div {
    background-color: ${(props) => props.theme.palette.items};
    margin: 0 1px;
    padding: 6px 10px;
  }
`;

const ProductSelectedBar = styled.div`
  width: 4px;
  padding: 0 !important;
  margin: 0 !important;
  background-color: ${(props) =>
    props.active
      ? props.theme.palette.primaryOutline
      : props.theme.palette.backgrounds} !important;
`;

const ProductValidator = styled.div`
  color: ${(props) =>
    props.valid ? props.theme.palette.success : props.theme.palette.error};
`;

const Type = styled.div`
  color: white;
`;

const ButtonIcon = styled.div`
  color: ${(props) => (props.active ? "white" : "#333")};
  background-color: ${(props) => props.theme.palette.items};
  transition: all 300ms ease;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;
