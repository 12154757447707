import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MainLayout } from "layouts";
import { SideTop, ScrollView, Button, RiseLoader } from "components";
import { times } from "lodash";
import { useCircularContext, useAuthContext } from "contexts";
import { 
  getRetailerAutocompleteApi,
  closeCirculars,
  addAdblocksToQueueByCircular,
  getTiers,
  addAdblocksToQueueByCirculars
 } from "api";
import { useInput } from "hooks";
import { stringify, parse } from "query-string";
import { navigate, useLocation } from "@reach/router";
import { SearchCircular, SearchCircularByID } from "./components";
import {
  TableHeader,
  FilterSection,
  TableRow,
  //ReportsSection,
} from "./components";

export const CircularsPage = () => {
  const [sortCol, setSortCol] = useState("");
  const [sortType, setSortType] = useState(0);
  const search = useInput("");
  const circID = useInput("");
  const location = useLocation();
  const searchParams = parse(location.search);
  const { userInfo } = useAuthContext();
  const [tiers, setTiers] = useState([]);
  const [selectedCirculars, setSelectedCirculars] = useState([]);
  const [isRequeuing, setIsRequeuing] = useState(false);

  if (!userInfo && !userInfo.user.is_superuser && !userInfo.user.is_moderator&& !userInfo.user.is_active) {
    navigate("/home");
  }

  const {
    getCirculars,
    getCircularByID,
    circulars = [],
    isLoading,
    isRefetching,
    totalCount = 0,
    totalPages,
    currentPage,
    loadMore,
    setFilter,
    stage,
    retailerId,
    creationDateFrom,
    creationDateTo,
    circDateFrom,
    circDateTo,
    adBlocksCount,
    error
  } = useCircularContext();

  const init = async () => {
    if (searchParams.circ_id){
      circID.setValue(searchParams.circ_id);
      getCircularByID(searchParams.circ_id);
    } else {
      circID.setValue("");
      getCirculars();
    }
    const res = await getTiers();
    if(res.success) {
       const tiersMapped = res.results.tiers.map((item) => ({
          ...item,
          title: item.ext_id,
          id: item.ext_id
        }));
        setTiers(tiersMapped);
    }else{
        //Raise Error
    }
  };

  useEffect(() => {
    setFilter({ retailerId: search.value, ...searchParams });
    init();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    search.setValue(searchParams.retailerId);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.retailerId]);

  useEffect(() => {
    circID.setValue(searchParams.circ_id);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.circ_id]);

  const handleSearch = async (params) => {
    navigate(
      `/circular/?${stringify({
        stage,
        retailerId,
        creationDateFrom,
        creationDateTo,
        circDateFrom,
        circDateTo,
        adBlocksCount
      })}`
    );
    circID.setValue("");
    getCirculars();
  };

  const handleSearchByCircularID = async (circ_id) => {
    navigate(
      `/circular/?${
        stringify({
          circ_id
        })
      }`
    );
    setFilter({circ_id: circID.value})
    getCircularByID(circ_id);
  }

  const handleSort = (sortCol, sortType) => {
    setSortCol(sortCol);
    setSortType(sortType);
    setFilter({ sortBy: sortCol, sortDir: sortType === 1 ? "ASC" : "DESC" });
    getCirculars();
  };

  const handleSelectRetailer = (params) => {
    setFilter({ retailerId: params });
  };

  const handleSearchReset = () => {
    localStorage.removeItem("addison-circular-state");
    window.location.href="/circular";
  };

  const handleCloseCircular = async (id) => {
    const res = await closeCirculars(id);
    if (res.success) {
      getCirculars();
    } else {
      // Error Message
    }
  };

  const handleRequeueCircular = async (id, high_priority=false) => {
    const result = await addAdblocksToQueueByCircular(id, high_priority);
    if (result.success){

    } else {
      // Error
    }
  };

  const queueCirculars = async (high_priority=false) => {
    if(selectedCirculars.length === 0) {
      //Put error message here
      return;
    }
    setIsRequeuing(true);
    const results = await addAdblocksToQueueByCirculars({
      circulars: selectedCirculars,
    },
      high_priority
    );
    if(results.success) {
      setSelectedCirculars([]);
    }else{
      // Error message
    }
    setIsRequeuing(false);
  };

  const selectAllCirculars = async () => {
    if(selectedCirculars.length === circulars.length) {
      setSelectedCirculars([]);
    }else{
      setSelectedCirculars(circulars.map((circular) => {
        return circular
      }));
    }
  };

  const selectCircular = async (circular) => {
    if (userInfo.user.is_superuser){
      let tempArray = selectedCirculars;
      const foundCircularIndex = selectedCirculars.indexOf(circular);
      //See if circular is in array
      if(foundCircularIndex !== -1) {
        //Remove clicked circular from list
        tempArray.splice(foundCircularIndex, 1);
        setSelectedCirculars((tempArray) => [
          ...tempArray
        ]);
      }else{
        //add clicked circular to list
        setSelectedCirculars((tempArray) => [
          ...tempArray,
          circular,
        ]);
      }
    }
  };

  return (
    <MainLayout>
      <MainContent>
        <SideSection>
          <SideTop
            currentCount={circulars.length}
            totalCount={totalCount}
            placeholder="circulars"
            isLoading={isLoading}
          />
          <SearchCircularByID 
            value={circID}
            onSearch={handleSearchByCircularID}
          />
          <SearchCircular
            isLoading={isLoading}
            func={getRetailerAutocompleteApi}
            onSearch={handleSearch}
            onSelect={handleSelectRetailer}
            {...search}
          />
          <FilterSection isLoading={isLoading} tiers={tiers} />
          {(circulars.length > 0 && !isLoading && userInfo.user.is_superuser) &&
            <div className="wrapper">
              <div className="title">Circular Operations</div>
              <div className="selectButton" onClick={selectAllCirculars}>
                <Link style={{ textDecoration: 'none' }}>
                  {selectedCirculars.length === circulars.length ? 'Deselect' : 'Select'} All Circulars
                </Link>
                {selectedCirculars.length > 0 &&
                <>
                  <div>
                    <Link
                      onClick={() => queueCirculars()}
                      style={{ textDecoration: 'none' }}
                    >Requeue Circulars</Link>
                  </div>
                  <div>
                     <Link
                      onClick={() => queueCirculars(true)}
                      style={{ textDecoration: 'none' }}
                      >HP Requeue Circulars</Link>
                  </div>
                </>
                }
              </div>
            </div>
          }
          {/* <ReportsSection isLoading={isLoading} /> */}
        </SideSection>
        <TableSection>
          <TableHeader
            sortCol={sortCol}
            sortType={sortType}
            onSort={handleSort}
          />
          <TableContent>

            {(isLoading || isRequeuing) ? (
              times(7, String).map((index) => (
                <TableRow key={`${index}key`} isLoading />
              ))
            ) : error && error.message ? (
              <div className="pt-4 mt-4 mx-auto text-center color-white">
                <p>Error: {error.message}</p>
                <p>If problem persists <Link onClick={handleSearchReset}>Reset Search</Link></p>
              </div>
            ) : circulars && circulars.length > 0 ? (
              circulars.map((item, index) => (
                <TableRow 
                key={`${index}key`} 
                data={item} 
                selectAll={selectedCirculars.length === circulars.length ? 'all' : selectedCirculars.length === 0 ? 'none' : null}
                onClick={(e) => selectCircular(e)}
                onButtonClick={(e) => handleCloseCircular(e)} 
                onQueueCircular={(e, hp) => handleRequeueCircular(e, hp)}/>
              ))
            ) : (
              <div className="pt-4 mt-4 mx-auto text-center color-white">
                No circulars match this search request.
              </div>
            )}


            {!isLoading && isRefetching ? (
              <div className="d-flex">
                <RiseLoader className="my-4 mx-auto" />
              </div>
            ) : !isLoading && currentPage + 50 < totalPages * 50 ? (
              <div className="my-4 d-flex justify-content-center">
                <Button
                  size="small"
                  width="120px"
                  onClick={() =>
                    loadMore({
                      retailer_id: search.value,
                    })
                  }
                >
                  Load More
                </Button>
              </div>
            ) : null}


          </TableContent>
        </TableSection>
      </MainContent>
    </MainLayout>
  );
};

const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;
  background: ${(props) => props.theme.palette.backgrounds};
`;

const SideSection = styled.div`
  width: 300px;
  border-right: 3px solid ${(props) => props.theme.palette.canvas};
  display: flex;
  flex-direction: column;
  
  .wrapper {
    overflow: auto;
    margin: 12px 12px 12px 24px;
    padding-right: 10px;
    min-width: 240px;
    ::-webkit-scrollbar {
      width: 10px;
      background: ${(props) => props.theme.palette.background};
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: ${(props) => props.theme.palette.background};
      border: 1px solid ${(props) => props.theme.palette.itemsHover};
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.palette.itemsHover};
      cursor: pointer;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
      cursor: pointer;
    }
  }

  .title {
    color:white;
    font-size:12px;
    border-bottom: 1px solid ${(props) => props.theme.palette.itemsHover};
    text-transform:uppercase;
  }
`;
const TableSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TableContent = styled(ScrollView)`
  position: relative;
  transition: all 300ms ease;
  border: ${(props) =>
    props.isDragAccept || props.isDragActive
      ? `1px dashed ${props.theme.palette.primary}`
      : "1px solid transparent"};
`;

const Link = styled.span`
    cursor:pointer;
    color:white;
    text-decoration:underline;
    &:hover {
      opacity:0.5;
    }
`;

