import React from "react";
import styled from "styled-components";
import { useMainContext } from "contexts";
import {
  DeletePageIncentiveModal,
  DeleteCircularIncentiveModal,
  DeleteAdblockIncentiveModal,
} from "modals";
import { useLocation } from "@reach/router";
import {
  FaCheckCircle,
  FaClone,
  FaTrash,
  FaMinusCircle,
  FaGift,
  FaInfoCircle,
} from "react-icons/fa";
import { useModal } from "hooks";

export const IncentiveItem = ({
  active,
  onClick = () => {},
  data,
  onClone = () => {},
  onRemove = () => {},
  index,
  incentiveData = [],
  isApproved = false,
  isPageIncentive = false
}) => {
  const { postPageEvents } = useMainContext();
  const deletePageModal = useModal();
  const deleteCircularModal = useModal();
  const deleteAdblockModal = useModal();
  const location = useLocation();

  const handleRemove = (event) => {
    event.stopPropagation();
    postPageEvents("click", `Incentive remove`);
    if(isPageIncentive){
      onRemove(event, data?.index);
    }
    else {
      switch (data?.incentive_attach_to) {
        case "Page":
          deletePageModal.openModal();
          break;
        case "Circular":
          deleteCircularModal.openModal();
          break;
        case "Adblock":
          if (location.pathname.startsWith("/offer/") && isApproved) {
            deleteAdblockModal.openModal();
          } else {
            onRemove(event, data?.index);
          }
          break;
        default:
          onRemove(event, data?.index);
          break;
      }
    }
  };

  const isValid = data?.isValid;
  return (
    <Items
      className="d-flex flex-row"
      active={active}
      onClick={() => {
        postPageEvents("click", `Incentive`);
        onClick();
      }}
    >
      <ProductSelectedBar active={active}></ProductSelectedBar>

      <ProductValidator valid={isValid}>
        {isValid ? <FaCheckCircle /> : <FaMinusCircle />}
      </ProductValidator>
      <Type>
        <FaGift />
      </Type>
      {/* {console.log(data)} */}
      {data?.existingIncentive && <Type>
        <FaInfoCircle/>
      </Type>}
      {isValid && !data?.group ? (
        <div className="flex-1">
          {data?.incentive_attach_to} - {data?.incentive_type ? `(${data?.incentive_type})` : ''} {data?.incentive_text}{" "}
          ({data?.incentive_limit ? `${data?.incentive_limit}` : ""})
          {data?.incentive_include_net_cost ? ` Net Cost` : ''}
        </div>
      ) : isValid && !data?.group && !data?.incentive_type && data?.incentive_text ? (
        <div className="flex-1">
          {data?.incentive_attach_to} - {data?.incentive_text}{" "}
        </div>
      ) : isValid && data?.group ? (
        <div className="flex-1">
           Group - ({data?.incentive_type}) - {data?.incentive_text}{" "}
          {data?.incentive_limit ? ` (${data?.incentive_limit})` : ""}
          {data?.incentive_include_net_cost ? ` (Net Cost)` : ''}
        </div>
      ) : (
        <div className="flex-1">{`Undeclared Incentive ${index + 1}`}</div>
      )}

      <ButtonIcon
        active
        onClick={(event) => {
          onClone(event, data?.index);
          postPageEvents("click", `Incentive clone`);
        }}
      >
        <FaClone />
      </ButtonIcon>

      <ButtonIcon active onClick={handleRemove}>
        <FaTrash />
      </ButtonIcon>
      <DeletePageIncentiveModal
        {...deletePageModal}
        onDelete={() => onRemove({}, data?.index)}
        text={data?.incentive_text}
        data={data}
        incentiveData={incentiveData}
      />
      <DeleteCircularIncentiveModal
        {...deleteCircularModal}
        onDelete={() => onRemove({}, data?.index)}
        text={data?.incentive_text}
        data={data}
        incentiveData={incentiveData}
      />
      <DeleteAdblockIncentiveModal
        {...deleteAdblockModal}
        onDelete={() => onRemove({}, data?.index)}
        text={data?.incentive_text}
        data={data}
        incentiveData={incentiveData}
      />
    </Items>
  );
};

const Items = styled.div`
  border: 1px solid
    ${(props) =>
      props.active
        ? props.theme.palette.primaryOutline
        : props.theme.palette.backgrounds};
  width: 100%;
  cursor: pointer;
  div {
    background-color: ${(props) => props.theme.palette.items};
    margin: 0 1px;
    padding: 6px 10px;
  }
`;

const ProductSelectedBar = styled.div`
  width: 4px;
  padding: 0 !important;
  margin: 0 !important;
  background-color: ${(props) =>
    props.active
      ? props.theme.palette.primaryOutline
      : props.theme.palette.backgrounds} !important;
`;

const ProductValidator = styled.div`
  color: ${(props) =>
    props.valid ? props.theme.palette.success : props.theme.palette.error};
`;

const Type = styled.div`
  color: white;
`;

const ButtonIcon = styled.div`
  color: ${(props) => (props.active ? "white" : "#333")};
  background-color: ${(props) => props.theme.palette.items};
  transition: all 300ms ease;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
`;
