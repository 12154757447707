import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Button } from "components";
import { DateRangePicker } from "react-date-range";
import { format } from "date-fns";
import { SPECIAL_DATES } from "config";
// import { useClickOutside } from 'react-click-outside-hook'
import { useOutsideClick } from "rooks";

export const DateRange = ({
  startDate = "",
  endDate = "",
  onChange = () => {},
  onClear = () => {},
  className,
  open = false,
  minDate = ""
}) => {
  const [uploadDates, setUploadDates] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const ref = useRef();
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [datePickerPos, setDatePickerPos] = useState({ x: 0, y: 0 });
  const buttonsRef = useRef();
  useOutsideClick(ref, onClickOutside);

  function onClickOutside() {
    if (isDatePickerVisible) {
      setIsDatePickerVisible(false);
    }
  }

  useEffect(() => {
    setUploadDates([
      {
        startDate: startDate ? new Date(`${startDate}`) : new Date(),
        endDate: endDate ? new Date(`${endDate}`) : new Date(),
        key: "selection",
      },
    ]);
    return () => {};
  }, [startDate, endDate]);

  const handleSelectUploadDate = (item) => {
    setUploadDates([item.selection]);
  };

  const handleOk = () => {
    onChange([
      format(new Date(`${uploadDates[0].startDate}`), "yyyy-MM-dd"),
      format(new Date(`${uploadDates[0].endDate}`), "yyyy-MM-dd"),
    ]);
    setIsDatePickerVisible(false);
  };
  const handleCancel = (params) => {
    setIsDatePickerVisible(false);
  };

  const handleSelectDate = () => {
    const { x, y } = buttonsRef.current.getBoundingClientRect();
    setDatePickerPos({
      x,
      y: y > window.innerHeight - 480 ? window.innerHeight - 480 : y,
    });
    setIsDatePickerVisible(true);
    ref.current.focus();
  };
  const handleClear = () => {
    onClear();
    setIsDatePickerVisible(false);
  };

  useEffect(() => {
    if (!open) {
      setIsDatePickerVisible(false);
    }
    return () => {};
  }, [open]);

  return (
    <Container className={className}>
      <div className="d-flex">
        <div className="d-flex justify-content-center">
          <Label>From:</Label>
          <DateInput onClick={handleSelectDate}>
            {startDate && !SPECIAL_DATES.includes(startDate)
              ? format(new Date(`${startDate}`), "MMM dd yyyy")
              : "-"}
          </DateInput>
        </div>
        <div className="d-flex justify-content-center">
          <Label>To:</Label>
          <DateInput onClick={handleSelectDate}>
            {endDate
              ? format(new Date(`${endDate}`), "MMM dd yyyy")
              : "-"}
          </DateInput>
        </div>
      </div>
      <div className="d-flex mt-2 justify-content-right" ref={buttonsRef}>
        {/* <Link size="xsmall" width="80px" onClick={handleClear}>
          Clear
        </Link> */}
      </div>
      <div className="position-relative">
        <DateRangeContainer
          visible={isDatePickerVisible}
          ref={ref}
          focus={isDatePickerVisible}
          pos={datePickerPos}
        >
          <DateRangePicker
            ranges={uploadDates}
            onChange={handleSelectUploadDate}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            direction="horizontal"
            months={1}
            renderStaticRangeLabel={(range) => {
              return range.label;
            }}
            inputRanges={[]}
            minDate={minDate ? minDate : undefined}
          />
          <DateRangeButtons className="d-flex mt-1">
            <Button
              size="small"
              className="ml-auto"
              width="90px"
              onClick={handleOk}
            >
              Ok
            </Button>
            <Button
              size="small"
              className="ml-2"
              width="90px"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              size="small"
              className="ml-2"
              width="90px"
              onClick={handleClear}
            >
              Clear
            </Button>
          </DateRangeButtons>
        </DateRangeContainer>
      </div>
    </Container>
  );
};

const Container = styled.div`
  .justify-content-right {
    justify-content: right;
  }
`;

const Label = styled.div`
  color: white;
  font-size: 12px;
  height: 28px;
  line-height: 28px;
`;

const DateInput = styled.div`
  width: 100px;
  height: 28px;
  line-height: 28px;
  font-size: 12px;
  border-bottom: 1px solid ${(props) => props.theme.palette.itemsHover};
  color: #e2e2e2;
  text-align: center;
`;

const DateRangeContainer = styled.div`
  display: ${(props) => (props.visible ? "block" : "none")};
  position: fixed;
  top: ${(props) => `${props.pos.y}px`};
  left: ${(props) => `${props.pos.x}px`};
  width: auto;
  z-index: 9;
  margin-top: 10px;
  background: white;
  .rdrDefinedRangesWrapper {
    display: none;
  }
`;
const DateRangeButtons = styled.div`
  border-top: 1px solid #e2e2e2;
  padding: 8px 12px 8px 0;
`;
