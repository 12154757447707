export const API_URL = "https://api.addisonpromo.com/";
export const HEADER_COLOR = "#2a90af";
export const HEADER_BANNER = "Development";
export const SUPER_USER_TABS = [
  {label: "CIRCULARS", url: "/circular"},
  {label: "ROLLUP", url: "/retailers-rollup"},
  {label: "RETAILERS", url: "/retailers"}, 
  {label: "TIERS", url: "/tiers/"},
  {label: "USERS", url: "/users"},
];

export const MODERATOR_TABS = [
  {label: "CIRCULARS", url: "/circular"},
  // {label: "ROLLUP", url: "/retailers-rollup"},
  {label: "USERS", url: "/users"},
];

export const ACTIVE_USER_TABS = [
  {label: "CIRCULARS", url: "/circular"}
];

export const COUNT_PER_PAGE = 50;
export const POST_COUNT_PER_PAGE = 50;
export const MAX_FILE_UPLOAD_SIZE = 1024 * 1024 * 500; // 500MB
export const TOKEN_EXPIRATION = 1000 * 60 * 30 * 24 * 60; // 1 week

export const DURATION_MIN = 1;
export const DURATION_MAX = 120;

export const SPECIAL_DATES = [
  "yesterday",
  "yesterweek",
  "yestermonth",
  "yesterquater",
];

export const JOB_STATUS_PROGRESS = {
  NEW: "Stage",
  CRAWL_PRE: "Scraping",
  CRAWL_IP: "Scraping",
  CRAWL_POST: "Scraping",
  PARSE_PRE: "Parsing",
  PARSE_IP: "Parsing",
  PARSE_POST: "Parsing",
  ETL_PRE: "Deliverables",
  ETL_IP: "Deliverables",
  ETL_POST: "Deliverables",
  DONE: "Finish",
};

export const PAGE_TYPES = [
  {
    "page_c": "1",
    "page_nm": "Home Page - Rotating Banner"
    
  },
  {
    "page_c": "2",
    "page_nm": "Daily Deals Page"
  },
  {
    "page_c": "3",
    "page_nm": "Deal Page"
  },
  {
    "page_c": "4",
    "page_nm": "Coupon Page"
  },
  {
    "page_c": "5",
    "page_nm": "Department Page"
  },
  {
    "page_c": "6",
    "page_nm": "Department Page - Rotating Banner"
  },
  {
    "page_c": "7",
    "page_nm": "Deal Page - Carousel"
  },
  {
    "page_c": "8",
    "page_nm": "Coupon Page - Rotating Banner"
  },
  {
    "page_c": "9",
    "page_nm": "Holiday Shop"
  },
  {
    "page_c": "A",
    "page_nm": "Main"
  },
  {
    "page_c": "B",
    "page_nm": "Back"
  },
  {
    "page_c": "C",
    "page_nm": "Classified"
  },
  {
    "page_c": "D",
    "page_nm": "Food"
  },
  {
    "page_c": "E",
    "page_nm": "Editorials"
  },
  {
    "page_c": "F",
    "page_nm": "Front"
  },
  {
    "page_c": "G",
    "page_nm": "Home & Garden"
  },
  {
    "page_c": "H",
    "page_nm": "Health"
  },
  {
    "page_c": "I",
    "page_nm": "Arts & Life"
  },
  {
    "page_c": "J",
    "page_nm": "Home Page"
  },
  {
    "page_c": "K",
    "page_nm": "Daily Deals Page - Rotating Banner"
  },
  {
    "page_c": "L",
    "page_nm": "Local News"
  },
  {
    "page_c": "M",
    "page_nm": "Middle"
  },
  {
    "page_c": "N",
    "page_nm": "Weekly Online Ad"
  },
  {
    "page_c": "O",
    "page_nm": "Deal Page - Rotating Banner"
  },
  {
    "page_c": "P",
    "page_nm": "Parade"
  },
  {
    "page_c": "Q",
    "page_nm": "Taste"
  },
  {
    "page_c": "R",
    "page_nm": "Newspaper"
  },
  {
    "page_c": "S",
    "page_nm": "Sports"
  },
  {
    "page_c": "T",
    "page_nm": "TPR"
  },
  {
    "page_c": "U",
    "page_nm": "Business"
  },
  {
    "page_c": "V",
    "page_nm": "Email"
  },
  {
    "page_c": "W",
    "page_nm": "Wrap"
  },
  {
    "page_c": "X",
    "page_nm": "Weekly Coupon Book"
  },
  {
    "page_c": "Y",
    "page_nm": "Weather"
  },
  {
    "page_c": "Z",
    "page_nm": "Puzzles & Comics"
  }
]

export * from "./offer";
